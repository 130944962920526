import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-imagecrop',
  templateUrl: './imagecrop.component.html',
  styleUrls: ['./imagecrop.component.scss']
})
export class ImagecropComponent implements OnInit {
  proimg: any = '';
  selectedFile: any = null;
  @Input() imageChangedEvent: any = '';
  @Output() onClose = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    console.log('dfsfdf',this.imageChangedEvent);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.proimg = event.base64;
    const fileToUpload: File = new File([this.dataURItoBlob(this.proimg)], 'filename.png');
    this.selectedFile = fileToUpload;
  }

  dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  Save() {
   var Image = {
      "selectedFile":this.selectedFile,
      "proimg":this.proimg
    }
    this.onAdd.emit(Image);
  }
 

  close() {
    this.onClose.emit("imgcrop");
  }


}
