import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownlistComponent } from './dropdownlist.component';



@NgModule({
  declarations: [DropdownlistComponent],
  imports: [
    CommonModule
  ],
  exports: [DropdownlistComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DropdownlistModule { }
