import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './Providers/auth.guard';

const routes: Routes = [

  { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: '1', loadChildren: () => import('./dash-board/dash-board.module').then(m => m.DashBoardModule), canActivate: [AuthGuard] },
  { path: '2', loadChildren: () => import('./actionPlans/action-plans/action-plans.module').then(m => m.ActionPlansModule), canActivate: [AuthGuard] },
  { path: '3', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },
  { path: '4', loadChildren: () => import('./icongrid/icongrid.module').then(m => m.IconGridModule), canActivate: [AuthGuard] },
  { path: '5', loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule), canActivate: [AuthGuard] },
  { path: 'hotnot', loadChildren: () => import('./hotnot/hotnot.module').then(m => m.HotnotModule), canActivate: [AuthGuard] },
  { path: '6', loadChildren: () => import('./performance/performance.module').then(m => m.PerformanceModule), canActivate: [AuthGuard] },
  { path: '7', loadChildren: () => import('./adminsettings/adminsettings.module').then(m => m.AdminSettingsModule), canActivate: [AuthGuard] },
  { path: '8', loadChildren: () => import('./Tasks/tasks/tasks.module').then(m => m.TasksModule), canActivate: [AuthGuard] },
  { path: 'messenger/:id', loadChildren: () => import('./messenger/messenger.module').then(m => m.MessengerModule), canActivate: [AuthGuard] },
  { path: 'Dash', loadChildren: () => import('./dash-board-compnt/dash-board-compnt.module').then(m => m.dashboardcompntModule), canActivate: [AuthGuard] },
  { path: '#aireport', loadChildren: () => import('./npreports/npreports.module').then(m => m.NpreportsModule), canActivate: [AuthGuard] },
 // { path: 'newmessenger/:id', loadChildren: () => import('./new-messenger/new-messenger.module').then(m => m.NewMessengerModule), canActivate: [AuthGuard] },
  { path: 'passwordreset/:id', loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetModule) },
  //{ path: '9', loadChildren: () => import('./userstats/usersrtas.module').then(m => m.NpreportsModule) },
  { path: '9', loadChildren: () => import('./user-statistics/user-statistics.module').then(m => m.UserStatisticsModule) },
  { path: '10', loadChildren: () => import('./performancepage/performancepage.module').then(m => m.PerformancepageModule), canActivate: [AuthGuard] },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
