<div class="loading" *ngIf="isLoading">
    <div class="loader">
        <div class="innerLoder one"></div>
        <div class="innerLoder two"></div>
        <div class="innerLoder three"></div>

    </div>
</div>
<div style="background-image: radial-gradient(#453e44 0%,#242223); width: 850px;" *ngIf="!isLoading">
    <div class="modal-header">
        <div class="row">
            <div class="col-md-8 align-self-center">
                <h5 class="modal-title" id="modal-basic-title">
                </h5>
            </div>
            <div class="col-md-4">
                <div aria-label="Close" style="font-size: 1.5rem;float: right;cursor: pointer;margin-right: 1rem;">
                    <span aria-hidden="true" style="color: white; padding-top: 1rem;cursor: pointer;" data-dismiss="modal" (click)="close()"> &times;</span>
                </div>
            </div>
        </div>
    </div>
    <div style="background-image: radial-gradient(#453e44 ,#242223,);;width: 850px;" *ngIf="!isLoading">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of lineChartData;let i =  index" class="slide">
                <span *ngIf="i != 0" style="color:darkgrey;float: left;position: relative;cursor: pointer;
			    left: 3.5rem;
    top: 1.3rem;" (click)="prev(i)">{{lineChartData[i-1].label}}</span>
                <!-- <h6 style="text-align: center;color:white;background:#1a1a1b;padding: 5px">{{slide.label}}</h6> -->
                <div id="js-legend" class="chart-legend flex-container">

                    <h6 style="text-align: center;color:white;padding: 5px">{{slide.label}}</h6>
                </div>
                <span *ngIf="i != (lineChartData.length-1)" style="color:darkgrey;float: right;position: relative;cursor: pointer;
			right: 4rem;" [ngStyle]="{'bottom': i!=0 ? '2.3rem' : '1.3rem' }" (click)="next(i)">{{lineChartData[i+1].label}}</span>

                <div style="margin-left: 2rem;width: 98%;display: flex;margin-top: 0.3rem;margin-right:1.5rem;">
                    <select (change)="changeSection($event)" class="rec_dropdwn" style="width: 30%;margin-right: 3rem;" [(ngModel)]="sectionType">
                            <option style="text-transform: capitalize;" [value]="1">Cash</option>
                            <option style="text-transform: capitalize;" [value]="6">Gross</option> 
                            <option style="text-transform: capitalize;" [value]="3">Expense</option> 
                            <option style="text-transform: capitalize;" [value]="4">comp</option> 
                            <option style="text-transform: capitalize;" [value]="5">Profit</option> 
                            <option style="text-transform: capitalize;" [value]="7">Inventory</option> 
                    </select>
                    <select (change)="changeDDL($event,i)" class="rec_dropdwn" style="width: 56%;" [(ngModel)]="nrSelect">
                        <optgroup style="text-transform: capitalize;" *ngFor="let item of ddlTrends | keyvalue;let i = index" [label]="item.key">
                            <option *ngFor="let it of item.value;let i = index" [value]="it.index" style="text-transform: capitalize;">{{it.label}}</option> </optgroup>
                    </select>




                </div>
                <div style="color:white;width: 1.9rem;text-align: end;position: absolute;top: 6.5rem;;" *ngIf="trendsChartName !=''">
                    <span>{{this.trendsChartName[0].includes('%')?'%':this.trendsChartName[0].includes('#')?'':'$'}}</span></div>
                <div id="js-legend" class="chart-legend flex-container">
                    <div class="flex-child magenta">

                    </div>&nbsp;&nbsp; ACTUALS&nbsp;&nbsp;&nbsp;
                    <div class="flex-child green">

                    </div> &nbsp;&nbsp; BENCHMARK

                </div>

                <canvas id="myCanvas" baseChart [datasets]="slide.data" [labels]="ChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins" style="background-image: radial-gradient(#453e44 ,#242223,);color:lightgray !important;padding: 1rem !important;">
				</canvas>

            </div>
        </ngx-slick-carousel>
    </div>
</div>