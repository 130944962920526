import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { UsdOnlyDirective } from '../usd-only.directive';



@NgModule({
  declarations: [UsdOnlyDirective],
  exports: [UsdOnlyDirective],
  providers: [DecimalPipe, CurrencyPipe]
})
export class UsdOnlyModule { }
