import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyusd'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: any, title: string): any {
    console.log(value, title, 'kk');
    if (value != undefined) {
      if (title.includes("#")) {
        return this.toFixedSpecial(value);
      }
      else {
        let r: any = this.toFixedSpecial(value)
        if (r.includes("-")) {
          let mr = r.replace('-', '');
          return "-$" + mr;
        } else {
          return '$' + r;
        }

      }
    }


  }

  toFixedSpecial(number) {
    var n = number
    if (n != null) {
      var parts = n.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); if (parts[1] > 0)
        return parts.join('.');
      else
        return parts[0];
    }
  }

}
