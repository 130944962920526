import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NPApi } from 'src/app/Providers/NP-Api';
import { AlertifyService } from 'src/app/Providers/alertify/alertify.service';
import { DatePipe } from '@angular/common'
import { environment } from 'src/environments/environment';
const userprofileimageurl = environment.ImageUrl1;
@Component({
  selector: 'app-taskadding',
  templateUrl: './taskadding.component.html',
  styleUrls: ['./taskadding.component.scss']
})
export class TaskaddingComponent implements OnInit {
  @Input() actionPlanId: any;
  @Input() taskid: any;
  submitted = false;
  approveStatus = '';
  approvedBy: any;
  ta_Priority: any = '1';
  isLoading = false;
  @Output() onClose = new EventEmitter();
  targetDate: any;
  assignee: any;
  userInfo: any;
  @Input() actionPlantype: any;
  type = "";
  followersId: any[] = [];
  followers: any[] = [];
  taskform: FormGroup;
  taskname: any
  taskdetails: any;
  inifollows: any;
  followersupdatearray: any[]
  @Output() onAdd = new EventEmitter();
  actionpalntitle: any;
  viewmode = false;
  minDate = moment(new Date()).format('YYYY-MM-DD')
  edittaskArray: any = [];
  createdby: any;
  envimage: any;
  taskArray;
  createDate:any;
  createdid:any;
  constructor(public datepipe: DatePipe, private modalService: NgbModal, private ngbActiveModal: NgbActiveModal,
    private fb: FormBuilder, private npSrv: NPApi, private alertfy: AlertifyService, private cd: ChangeDetectorRef) {
    this.targetDate = moment(new Date()).format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.envimage = userprofileimageurl
    this.taskform = this.fb.group({
      targetDate: [this.targetDate, ''],
      taskname: ['',],
      taskdetails: [''],
      priority: ['1'],

    }
    );
    // this.getdata();
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));

    if (this.actionPlantype == 'V') {
      this.viewmode = true;
    } else {
      this.viewmode = false;

    }


  }
  ngOnChanges(): void {

    if (this.actionPlantype == 'E' || this.actionPlantype == 'V') {

      let obj = {
        "Id": this.taskid,
        "expression": ""
      }


      //this.alertContent = alertContent;
      console.log(obj);

      this.isLoading = true;
      this.npSrv.gettaskbyid(obj).subscribe((res: any) => {

        // this.isLoading = false;
        if (res.status == 200) {
          this.edittaskArray = res.response.Tasks.data;
          console.log(this.edittaskArray);
          if (this.edittaskArray[0] != undefined && this.edittaskArray[0] != null) {


          this.minDate = this.datepipe.transform(this.edittaskArray[0].ta_DueDate[0], 'yyyy-MM-dd');
          this.taskname = this.edittaskArray[0].ta_TaskName[0];
          this.taskdetails = this.edittaskArray[0].ta_TaskDetails[0]
          this.createdby = this.edittaskArray[0].CreatedBy;
          this.createDate=this.edittaskArray[0].ta_Ts;
          this.createdid=this.edittaskArray[0].ta_U_UId;
          this.ta_Priority = this.edittaskArray[0].ta_Priority.join();
          console.log('  this.ta_Priority', this.ta_Priority[0])
          this.actionpalntitle = this.edittaskArray[0].ap_title[0];
          if (this.edittaskArray[0].Assignedto[0].Assignee != 0 && this.edittaskArray[0].Assignedto[0].Assignee != undefined) {

            this.assignee = this.edittaskArray[0].Assignedto[0].Assignee[0].Assign[0];
          }

          if (this.edittaskArray[0].Followers[0] != 0 && this.edittaskArray[0].Followers[0] != undefined) {
            this.followersupdatearray = this.edittaskArray[0].Followers[0].Follower
            this.followersupdatearray.forEach(element => {
              this.followers.push(element)
            this.isLoading = false;
            });
          }


        }

        }
      }, err => {
        this.isLoading = false;
      })
      setTimeout(() => {

        
      }, 0);

    }
    console.log(this.taskname, 'j');


  }

  closeModal() {
    this.onClose.emit(this.taskArray);
    this.ngbActiveModal.close();
  }
  openActionPlanFollowers(content, type) {

    /// type == "A" ? this.type = 'A' : type = 'F' ? this.type = 'F' : this.type = "AP"
    if (type == 'A') {
      this.type = 'A';
    } else if (type == "F") {
      this.type = "F";
    }
    else {
      this.type = 'AP';
    }
    this.ngbActiveModal = this.modalService.open(content, { size: 'lg', backdrop: 'static', });
  }

  onClosemsg() {
    this.ngbActiveModal.close();
  }

  onCloseAlert(type, content) {
    if (type == 'D') {
      // this.deleteaction();
      this.ngbActiveModal.close();
    } else if (type == "E") {
      this.ngbActiveModal.close();
      this.onClose.emit();

      // this.editActionPlanFromPopup();
      //this.isLoading = true;
      setTimeout(() => {

        this.ngbActiveModal = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
      }, 1000);
      //this.isLoading = false

    } else if (type == 'A') {
      // this.achieveActionPlan();
    }else if(type == "C"){​​​​​​​​
      this.complete();
     } else {
      // /this.onSubmit();
      this.ngbActiveModal.dismiss();
    }

  }
  closeModalf(data) {
    this.ngbActiveModal.dismiss();
  }
  changeImg(data) {
    //this.followers = []
    if (this.type == "F") {
      if (data.followerIds.length != 0) {
        for (let i = 0; i < data.followerIds.length; i++) {
          this.followersId.push(data.followerIds[i])
        }
      }


      if (data.followers.length != 0) {
        this.followers = [];
        for (let i = 0; i < data.followers.length; i++) {
          this.followers.push(data.followers[i]);
          //this.followersId.push(data.followers[i].sc_id)

        }
      }



      if (data.unFollowers.length != 0) {
        for (let j = 0; j < data.unFollowers.length; j++) {
          for (let i = 0; i < this.followers.length; i++) {
            if (this.followers[i].sc_id == data.unFollowers[j].sc_id) {
              this.followers.splice(i, 1)
            }
            ///this.followersId.splice(this.followersId.indexOf(data.unFollowers[i].sc_id), 1)
          }
        }
      }
    }
    this.type == 'A' ? this.assignee = data.assignee : this.assignee;
    this.approvedBy = this.type == 'AP' ? this.approvedBy = data.approveBy : this.approvedBy;
    if (this.type == 'AP') {
      if (this.actionPlantype == 'E') {
        this.approveStatus = "N";
        // this.approveId = this.approvedBy.sc_id;
      }
    }
    //this.type == 'A' ? this.followersId = this.followersId.push(this.followers.sc) : this.assignee;
    if (this.type == 'A') {
      if (data.followers.length != 0) {
        for (let i = 0; i < data.followers.length; i++) {
          this.followersId.push(data.followers[i].sc_id)
        }
      }
    }

    this.ngbActiveModal.dismiss();
  }

  onSubmit() {
    console.log('hi', this.actionPlanId);


    let followId: any[] = [];
    if (this.followers.length != 0) {
      for (let k = 0; k < this.followers.length; k++) {
        followId.push(this.followers[k].sc_id)
      }
    }
    let uids = followId.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
    //this.dueDate = new Date().toISOString().substring(0, 10);;
    let userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    let assigned = this.assignee != undefined ? this.assignee.sc_id : 0;
    // if (this.actionPlanId != 0) {
    if (this.taskform.value.taskname != '' && this.taskform.value.taskname != undefined) {
      let obj = {
        ta_Id: "0",
        ta_Ticket: this.userInfo.id,
        ta_U_UId: userInfo.id,
        ta_TS_Id: 1,
        ta_acp_id: this.actionPlanId != undefined ? this.actionPlanId : 0,
        ta_TaskName: this.taskform.value.taskname,
        ta_TaskDetails: this.taskform.value.taskdetails,
        ta_Status: "Y",
        ta_DealerId: localStorage.getItem('store_id'),
        ta_Priority: this.ta_Priority,
        ta_TaskType: (this.actionPlanId != 0) ? 'A' : 'G',
        ta_Loginfrom: "D",
        ta_ParentId: 0,
        AST_FollowesIds: uids.join(),
        Assignto_uid: assigned,
        ta_DueDate: this.taskform.value.targetDate,
        action: "A"
      }

      if (this.actionPlanId == 0) {
        this.npSrv.addAndUpdateTaskAssign(obj).subscribe((resTask: any) => {
          if (resTask.status == 200) {
            this.alertfy.success('Task Added Successfully!');
            let param: any = { obj: "yes", obj1: 123, taskArray: obj };
            this.closeModal();
            this.ngbActiveModal.dismiss();
            this.onAdd.emit(param)
            this.assignee = undefined;

          }
        }, err => {

        })
      } else {
        let data: any;

        data = this.assignee;
        console.log(data);

        const result = `<Assignee><Assign><sc_id>${data.sc_id}</sc_id><sc_firstname>${data.sc_firstname}</sc_firstname><sc_lastname>${data.sc_lastname}</sc_lastname><sc_profile_image>${data.sc_profile_image}</sc_profile_image><sc_title>${data.sc_title}</sc_title></Assign></Assignee>`;
        console.log(result)
        let obj = {
          ta_Id: "0",
          ta_Ticket: "1",
          ta_U_UId: userInfo.id,
          ta_TS_Id: 1,
          ta_acp_id: this.actionPlanId,
          ta_TaskName: this.taskform.value.taskname,
          ta_TaskDetails: this.taskform.value.taskdetails,
          ta_Status: "Y",
          ta_DealerId: localStorage.getItem('store_id'),
          ta_Priority: this.ta_Priority,
          ta_TaskType: (this.actionPlanId != 0) ? 'A' : 'G',
          ta_Loginfrom: "D",
          ta_ParentId: 0,
          AST_FollowesIds: uids.join(),
          Assignto_uid: assigned,
          ta_DueDate: new Date(this.targetDate),
          Assignedby: result,
          action: "A"
        }
        //console.log(obj, this.taskform.value.targetDate, this.targetDate, 'j');
         this.npSrv.addAndUpdateTaskAssign(obj).subscribe((resTask: any) => {
          if (resTask.status == 200) {
            this.alertfy.success('Task Added Successfully!');
            let param: any = { obj: "yes", obj1: 123, taskArray: obj };
            this.closeModal();
            this.ngbActiveModal.dismiss();
            this.onAdd.emit(param)
            this.assignee = undefined;

          }
        }, err => {

        })
        // this.alertfy.success('Task Added Successfully!');
        // let param: any = { obj: "yes", obj1: 123, taskArray: obj };
        // this.closeModal();
        // this.ngbActiveModal.dismiss();
        // this.onAdd.emit(param)
      }
    }
    else {
      this.alertfy.warning("Please Enter Task Name!")
    }





  }

  onEditSubmit() {

    let assigned = this.assignee != undefined ? this.assignee.sc_id : 0;
    let followId: any[] = [];
    if (this.followers.length != 0) {
      for (let k = 0; k < this.followers.length; k++) {
        followId.push(this.followers[k].sc_id)
      }
    }
    let uids = followId.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    })
    if (this.taskname != 0 && this.actionPlantype == 'E') {

      let obj = {

        "ta_Id": this.edittaskArray[0].ta_Id.join(),
        "ta_Ticket": this.edittaskArray[0].ta_Ticket,
        "ta_U_UId": this.edittaskArray[0].ta_U_UId.join(),
        "ta_TS_Id": this.edittaskArray[0].ta_TS_Id.join(),
        "ta_acp_id": this.edittaskArray[0].ta_acp_id.join(),
        "ta_TaskName": this.taskname,
        "ta_TaskDetails": this.taskdetails,
        "ta_Status": this.edittaskArray[0].ta_Status.join(),
        "ta_DealerId": localStorage.getItem('store_id'),
        "ta_Priority": this.ta_Priority,
        "ta_TaskType": this.edittaskArray[0].ta_TaskType.join(),
        "ta_Loginfrom": this.edittaskArray[0].ta_Loginfrom.join(),
        "ta_ParentId": "1",
        "AST_FollowesIds": uids.join(),
        "Assignto_uid": assigned,
        "action": "U",
        "ta_DueDate": this.taskform.value.targetDate

      }
      console.log('obj', obj);

      this.npSrv.addAndUpdateTaskAssign(obj).subscribe((resTask: any) => {
        if (resTask.status == 200) {
          this.alertfy.success("Task Updated Successfully")
          this.closeModal();
          this.ngbActiveModal.dismiss();
          let param: any = { obj: "yes", obj1: 123 }
          this.ngbActiveModal.dismiss();
          this.onAdd.emit(param)
          this.assignee = undefined;

        }
      }, err => {

      })
    } else {
      this.alertfy.warning("Please Enter Task Name!")

    }
  }

  alertContent:any = "";
  del_id:any;
  actionPlantypeAlert:any;
  completedtask(temp, flag, content) {
    this.actionPlantypeAlert = flag;
    this.alertContent = content;
     this.del_id = this.edittaskArray[0].ta_Id.join();
    this.ngbActiveModal = this.modalService.open(temp, { size: 'sm', backdrop: 'static', windowClass: 'Center-aligned' });
  }

  complete() {
    let obj = 
      {
        "ta_Id":this.del_id,  
    };
    this.npSrv.taskcomplete(obj).subscribe((dataRes: any) => {
      if (dataRes.status == 200) {
        this.alertfy.success('Task Completed Successfully');
        let param: any = { obj: "yes", obj1: 123, taskArray: obj };
        this.closeModal();
        this.ngbActiveModal.dismiss();
        this.onAdd.emit(param)
      }
    });
  }

  
}
