import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NPApi } from 'src/app/Providers/NP-Api';
import { environment } from 'src/environments/environment';
import * as fileSaver from 'file-saver';
import { AlertifyService } from 'src/app/Providers/alertify/alertify.service';
const apiconn = environment.AttachUrl;


@Component({
  selector: 'app-action-plan-attach',
  templateUrl: './action-plan-attach.component.html',
  styleUrls: ['./action-plan-attach.component.scss']
})
export class ActionPlanAttachComponent implements OnInit {
  @Input() actionId: any;
  @Input() imgdata: any = [];
  @Output() onClose = new EventEmitter();
  @Output() onAddFile = new EventEmitter();
  userInfo: any;
  proimg: any = [];
  showimg: boolean = true;
  showchangeimg: boolean = false;
  selectedFile: any = [];
  imageChangedEvent: any = '';
  croppedImage: any = '';
  docfilename: '';
  filename: string = '';
  FilesData: any = [];
  ext: string = '';
  isif: boolean = false;
  apiconn1: string = '';
  isLoading = false;
  ImageLoader = false;

  constructor(private modalService: NgbModal, private ngbActiveModal: NgbActiveModal,
    private sanitizer: DomSanitizer, private npSrv: NPApi, private alertify: AlertifyService,) { }

  ngOnInit(): void {
    this.apiconn1 = environment.AttachUrl + 'thumbs/'
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    if (this.actionId != '') {
      this.FilesData = [];
      this.getfiles();
    }
    else if (this.imgdata.length > 0) {
      if (this.imgdata[0].Img.length > 0) {
        this.proimg = this.imgdata[0].Img;
        this.selectedFile = this.imgdata[0].data;
        this.showchangeimg = true;
      }
    }
  }

  close() {
    // if(this.actionId != '')
    this.onClose.emit(this.FilesData.length)
    // else
    // this.onClose.emit(this.selectedFile.length)
  }

  getfiles() {
    console.log(this.actionId);
    let obj = { apaid: this.actionId, expression: '' }
    this.isLoading = true;
    this.npSrv.getattachmenatsByid(obj).subscribe((res: any) => {
      console.log(res)
      if (res.status == 200) {
        this.FilesData = res.response;
        this.isLoading = false;
        console.log(this.FilesData);
      }
      else {
        this.isLoading = false;

      }
    }, error => console.info(error),

      () => console.info('File getting Error'))
  }

  fileChangeEvent(event: any): void {
    // this.proimg = event.target.files[0];
    let reader = new FileReader();
    reader.onload = (e: any) => {
      this.proimg.push({ 'img': e.target.result, 'ext': event.target.files[0].name.substr(event.target.files[0].name.lastIndexOf('.') + 1) });
    }
    reader.readAsDataURL(event.target.files[0]);
    // this.selectedFile.push(<File>event.target.files[0]);
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFile.push(event.target.files[i]);
    }
    this.showimg = false;
    this.showchangeimg = true;
    console.log(this.proimg);
    console.log(this.selectedFile);
    // this.saveimg();
    // this.imageChangedEvent = event;
    // console.log(this.imageChangedEvent);
    // this.ngbActiveModal =  this.modalService.open(content, { size: 'lg',  });
  }

  closeModal(id) {
    this.ngbActiveModal.dismiss();
  }

  changeImg(data) {
    console.log(data);
    this.selectedFile = data.selectedFile;
    this.proimg = data.proimg;
    this.showimg = false;
    this.showchangeimg = true;
    this.ngbActiveModal.dismiss();
  }

  saveFiles() {
    if (this.actionId != '') {
      this.isLoading = true;
      const input = new FormData();
      input.append('action', 'A');
      input.append('apa_acp_id', this.actionId);
      input.append('apa_file_name', "test.png");
      input.append('apa_userid', this.userInfo.id);
      input.append('apa_updatedUid', this.userInfo.id);
      // input.append('file', this.selectedFile);
      for (let i = 0; i < this.selectedFile.length; i++) {
        input.append('file', this.selectedFile[i]);
      }
      this.npSrv.addActtionPlanAttachments(input).subscribe((dataRes: any) => {
        console.log(dataRes);
        this.isLoading = false;
        if (dataRes.status == 200) {
          this.proimg = [];
          this.selectedFile = [];
          this.getfiles();
          //this.alertify.success(dataRes.response)
          // let param: any = { obj: "yes", obj1: 123 }
          // this.modalService.dismissAll();
          // this.onAdd.emit(param)
          // this.alertify.success(res)
        } else {
          // this.alertify.warning(dataRes.error)
        }
      })
    }
    else {
      console.log('test', this.selectedFile);
      var data = [{ Img: this.proimg, data: this.selectedFile }];
      this.onAddFile.emit(data);
    }
  }

  opendocs(filename) {
    this.ImageLoader = true;
    setTimeout(() => {
      this.filename = apiconn + filename;
      this.ImageLoader = false;
    }, (500));   
  }

  deleteAttachs(id) {
    // const input = new FormData();
    //     input.append('action', 'D');
    //     input.append('apa_id', id);
    //     input.append('apa_acp_id', this.actionId);
    //     input.append('apa_userid', this.userInfo.u_uid);
    //     input.append('apa_updatedUid', this.userInfo.u_uid); 
    let input = {
      action: 'D',
      apa_id: id,
      apa_acp_id: this.actionId,
      apa_userid: this.userInfo.id,
      apa_updatedUid: this.userInfo.id
    }
    this.npSrv.deleteActtionPlanAttachments(input).subscribe((dataRes: any) => {
      console.log(dataRes);
      if (dataRes.status == 200) {
        this.proimg = [];
        this.filename = '';
        this.getfiles();
        this.alertify.success(dataRes.response)
        // let param: any = { obj: "yes", obj1: 123 }
        // this.modalService.dismissAll();
        // this.onAdd.emit(param)
        // this.alertify.success(res)
      } else {
        // this.alertify.warning(dataRes.error)
      }
    })
  }

  download(file) {
    this.npSrv.downloadFile(file).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      //window.open(url);
      //window.location.href = response.url;
      fileSaver.saveAs(blob, file);
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }


}
