import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionPlanTakComponent } from './action-plan-tak.component';
import { ActonPlanFollowersModule } from '../acton-plan-followers/acton-plan-followers.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaskaddingModule } from 'src/app/Tasks/taskadding/taskadding.module';
import { LoaderAnimationModule } from 'src/app/views/loader-animation/loader-animation.module';

import { DpDatePickerModule } from 'ng2-date-picker';

@NgModule({
  declarations: [ActionPlanTakComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgbModalModule,
    ActonPlanFollowersModule,
    FormsModule,
    ReactiveFormsModule,
    TaskaddingModule,
    LoaderAnimationModule,
    DpDatePickerModule
  ],
  exports: [ActionPlanTakComponent],
  entryComponents: [ActionPlanTakComponent],
  providers: [NgbActiveModal]
})
export class ActionPlanTakModule { }
