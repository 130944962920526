import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, Chart } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Common } from '../common';
import { NPApi } from '../Providers/NP-Api';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Router } from '@angular/router';
@Component({
  selector: 'app-linechat',
  templateUrl: './linechat.component.html',
  styleUrls: ['./linechat.component.scss']
})
export class LinechatComponent implements OnInit {
  lineChartData: ChartDataSets[] = [];

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  monlist = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  lineChartLabels: Label[] = [];
  isLoading = false;
  sectionType='1';
  lineChartOptions = {
    legend: {
      display: false,
      maxSize: {
        height: 100,
        innerWidth: 50,

      },
      labels: {
        usePointStyle: false,
        padding: 20,
        fontColor: "#fff",
        display: false
      }
    },

    tooltips: {
      backgroundColor: '#fff',
      displayColors: false,
      labelTextColor: '#1a1a1b',
      titleFontColor: '#1a1a1b',
      bodyFontColor: '#1a1a1b',
      titleAlign: 'center',
      bodyAlign: 'center',
      // label: '$' + '',
      // title: ''
      callbacks: {
        label: function (tooltipItem, data) {
          var datasetLabel = '';
          var label = data.labels[tooltipItem.index];
          if (data.datasets[tooltipItem.datasetIndex].label === 'Actual') {
            // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toString();
            // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].split(/(?=(?:...)*$)/);
            // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].join(',');
            // return "$" + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            var n = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] < 0 ? data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

            var parts = n.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '$' + parts[0];
            return '$' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          } else if (data.datasets[tooltipItem.datasetIndex].label === 'Benchmark') {
            // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toString();
            // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].split(/(?=(?:...)*$)/);
            // data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].join(',');
            var n = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] < 0 ? data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] : data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

            var parts = n.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return '$' + parts[0];
            // return "$" + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return '$' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          } else {
            return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          }

        }
      }
    },

    responsive: true,
    segmentShowStroke: false,
    style: {
      strokewidth: 5
    },
    scales: {
      scaleLabel: {
        lineHeight: 1.2,
        padding: 4
      },
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: true,
          stacked: true
        },
        ticks: {
          fontColor: '#ffff',
          fontFamily: 'Gotham\ Book\ Regular',
        },
      }],
      yAxes: [{
        gridLines: {
          display: true,
          color: '#e2e1e1',
          lineWidth: 0.5,
          drawBorder: false,
          showBorder: true,
          zeroLineColor: '#e2e1e1'
        },
        afterDataLimits: function (axis) {
          axis.max = Math.max(...axis.chart.config.data.datasets[0].data) < Math.max(...axis.chart.config.data.datasets[1].data) ? Math.max(...axis.chart.config.data.datasets[1].data) : Math.max(...axis.chart.config.data.datasets[0].data) // add 1px to bottom
          axis.min = Math.min(...axis.chart.config.data.datasets[0].data) < Math.min(...axis.chart.config.data.datasets[1].data) ? Math.min(...axis.chart.config.data.datasets[0].data) : Math.min(...axis.chart.config.data.datasets[1].data) // add 1px to bottom
          ///console.log(axis.min, axis.max, Math.min(...axis.chart.config.data.datasets[0].data), Math.min(...axis.chart.config.data.datasets[1].data),
          // Math.max(...axis.chart.config.data.datasets[1].data), Math.max(...axis.chart.config.data.datasets[0].data), 'kk');

        },
        ticks: {
          beginAtZero: true,
          fontColor: '#ffff',
          fontFamily: 'Gotham\ Book\ Regular',
          fontStyle: '100',
          //stepSize: 120000,

          userCallback: function (value, index, values) {
            // Convert the number to a string and splite the string every 3 charaters from the end

            //return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (value == 0) {
              return 0;
            }
            else {
              // hundreds
              if (value <= 999 && value <= -999) {
                if ((value <= -1000 && value >= -999999)) {
                  return (value / 1000).toFixed(0) + "K";
                } else if ((value <= -1000000 && value >= -999999999)) {
                  return (value / 100000).toFixed(0) + 'm';
                } else if ((value <= -1000000000 && value >= -999999999999)) {
                  return (value / 1000000000).toFixed(0) + 'b';
                }
                return value;
              }
              // thousands
              else if ((value >= 1000 && value <= 999999) || (value >= -1000 && value <= -999999)) {
                //return this.toFixedSpecial_total(value);
                //console.log(value);

                return (value / 1000).toFixed(0) + "K";
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }
              // millions
              else if ((value >= 1000000 && value <= 999999999)) {
                return (value / 1000000).toFixed(1).replace(/\.0+$/, '') + 'm';
              }
              // billions
              else if ((value >= 1000000000 && value <= 999999999999)) {
                return (value / 1000000000).toFixed(1).replace(/\.0+$/, '') + 'b';
              }
              else
                return value;
            }

          },
          padding: 10,

        },
      }]
    }
  };
  lineChartColors: Color[] = [


    {

      pointBackgroundColor: '#ee1b21',
      pointHoverBackgroundColor: '#ee1b21',
      borderColor: ' #ee1b21',
      pointBorderColor: '#ee1b21',
      pointHoverBorderColor: '#ee1b21'
    }, {

      pointBackgroundColor: '#4189cb',
      pointHoverBackgroundColor: '#4189cb',
      borderColor: '#4189cb',
      pointBorderColor: '#4189cb',
      pointHoverBorderColor: '#4189cb'
    }
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  section: any;
  TrendsData: any = [];
  Tdata: any = [];
  Tdata1: any = [];
  chatdata: any = [];
  chatdata1: any = [];
  nrSelect:any = 0;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
    // speed: 1400,

    prevArrow: '<button class="slide-arrow1 slick-prev1"></button>',
    nextArrow: '<button class="slide-arrow1 slick-next1"></button>'
  };
  dat: any;
  ChartLabels: any[];
  constructor(public comm: Common, private api: NPApi, private ngbActiveModal: NgbActiveModal, private router: Router) { }
  Linechart: any = [];
  @Input() public seclabel;
  @Input() public subSection;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  ddlTrends=[];
  ngOnInit(): void {
    this.sectionType=this.comm.selectedItem;
    this.getTrends();
  }
 groupArrayOfObjects(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  monthName(val) {
    return this.monlist[val - 1];
  }

  close() {
    this.passEntry.emit();
    this.ngbActiveModal.dismiss();
    // this.comm.dbflag = 2;
    // this.comm.selectedlink = 4;

    // this.router.navigate(['1'])
  }

  next(index) {
    console.log(index);

    this.slickModal.slickNext();
    //this.nrSelect = index;
  }

  prev(index) {
    this.slickModal.slickPrev();
    //this.nrSelect = index;
    
  }

  changeDDL(ev, index) {
    //console.log(ev.target.value, index, 'vv');

    this.nrSelect = ev.target.value;
    const selectedIndex = ev.target.selectedIndex;
    const optGroupLabel = ev.target.options[selectedIndex].parentNode.getAttribute('label');
    // console.log(optGroupLabel);
    this.trendsChartName = this.ddlTrends[optGroupLabel].filter(x => x.index == ev.target.value).map(x=>x.label);
    console.log(this.trendsChartName);
   
    // console.log(symbol);
    this.isLoading = true;
    setTimeout(() => {
      this.slickModal.slickGoTo(ev.target.value)
      this.isLoading = false;
    }, 0);
    this.isLoading = false

  }

trendsChartName="";
  getTrends(){
    this.trendsChartName="$";
    this.isLoading = true;
    this.Linechart = [];
    this.lineChartData=[];
    this.lineChartLabels = [];
    let storeid = localStorage.getItem('store_id');
    this.section = (this.comm.selectedItem == 1) ? 'cash' : (this.comm.selectedItem == 6) ? 'revenue' : (this.comm.selectedItem == 3) ? 'expense' : (this.comm.selectedItem == 4) ? 'compensation' : (this.comm.selectedItem == 5) ? 'profit' : 'inventory';

    this.api.getTrendDetails(storeid, this.comm.localStorageItem('monthYear'), this.section).subscribe((data: any) => {
      if (data.status == 200) {

        if (data.response.length > 0) {
          data.response.forEach((ele, i) => {
            if (ele.Actual_trends != null) {
              this.Tdata = ele.Actual_trends.split(',');
              this.ddlTrends = data.response.map((e,i)=>{
                
                let obj={
                  subsection:e.subsection.replace(/_/g, " "),
                  label:e.Label,
                  index:i,
                  dbkey:e.lblID
                }
                return obj;
              })
              // this.ddlTrends.push(trendsData)
              // console.log(this.ddlTrends,'b');
              
              this.ddlTrends=this.groupArrayOfObjects(this.ddlTrends,"subsection");
              // console.log(this.ddlTrends,'lp');
              this.Tdata.forEach(element => {
                let val = element.split('|')[1];
                // let dat = element.split('|')[0].substr(0, 4) + ' ' + this.monthName(element.split('|')[0].substr(4, 2));
                let dat = this.monthName(element.split('|')[0].substr(4, 2)) + '/' + element.split('|')[0].substr(2, 2);
                if (i == 0) {
                  this.lineChartLabels.push(dat);
                }
                this.chatdata.push(parseInt(val));
              });

            }
            if (ele.benchmark_trends != null) {
              this.Tdata1 = ele.benchmark_trends.split(',');

              this.Tdata1.forEach(element => {
                let val = element.split('|')[1];
                // this.dat = element.split('|')[0].substr(0, 4) + ' ' + this.monthName(element.split('|')[0].substr(4, 2));
                //this.dat = this.monthName(element.split('|')[0].substr(4, 2)) + '/' + element.split('|')[0].substr(2, 2);
                // this.lineChartLabels.push(dat);
                // if (i == 0) {
                //   this.lineChartLabels.push(this.dat);
                // }
                this.chatdata1.push(parseInt(val));
              });
            }
            //console.log(this.lineChartLabels);

       //    this.ChartLabels =this.lineChartLabels;
            // let labels = this.ChartLabels.filter(function (item, index, inputArray) {
            //   return inputArray.indexOf(item) == index;
            // });
            // this.ChartLabels = labels;
            let obj = { data: [], label: '' };
            obj.data[0] = {
              data: this.chatdata.reverse(),
              label: 'Actual',
              lineTension: 0,
              fill: false,
              pointBorderColor: '',
              labels: this.ChartLabels,
            };
            obj.data[1] = {
              data: this.chatdata1.reverse(),
              label: 'Benchmark',
              lineTension: 0,
              fill: false,
              pointBorderColor: '',
            labels: this.ChartLabels,
            };
            obj.label = ele.Label;
            this.lineChartData.push(obj);



            this.chatdata = [];
            this.chatdata1 = [];
           // this.ChartLabels = [];
            //Chart.defaults.global.




          });
                    this.ChartLabels = [...this.lineChartLabels.reverse()];
          
                    let labels = this.ChartLabels.filter(function (item, index, inputArray) {
                      return inputArray.indexOf(item) == index;
                    });
                    this.ChartLabels = labels;
                   
                    
                    if(this.seclabel != "" && this.subSection!=""){
                      console.log(this.subSection,this.seclabel,'mm');
                      console.log(this.subSection,this.seclabel,'mm');
                    this.isLoading = true;  
                      let index = this.ddlTrends[this.subSection.replace(/_/g, " ")].filter(x => x.dbkey == this.seclabel).map(x=>x.index);
                      setTimeout(() => {
                      this.trendsChartName = this.ddlTrends[this.subSection.replace(/_/g, " ")].filter(x => x.index == index).map(x=>x.label);  
                      }, 1000);
                      
                      console.log(this.trendsChartName);
                      // index =index > 0?index + 1:index;
                      this.nrSelect = index;
                      setTimeout(() => {
                        this.slickModal.slickGoTo(index)
                        this.isLoading = false;
                      }, 0);
                    }
                    
          // console.log(this.lineChartColors, this.ddlTrends, 'hh');
          this.isLoading = false;
          //console.log(this.ChartLabels, labels);

        }

      }
    }, err => {
      this.isLoading = false;
    });
  }

  changeSection(ev){
    //console.log(this.sectionType,ev.target.value);
    this.subSection="";
    this.seclabel ="";
    this.comm.selectedItem = this.sectionType;
    this.getTrends();
    
  }

}
