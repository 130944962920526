import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardSideNavComponent } from './dashboard-side-nav.component';
import { TaskaddingModule } from '../Tasks/taskadding/taskadding.module';
import { CreateActionPlanModule } from '../actionPlans/create-action-plan/create-action-plan.module';




@NgModule({
  declarations: [DashboardSideNavComponent],
  imports: [
    CommonModule,
    TaskaddingModule,
    CreateActionPlanModule
  ],
  exports: [DashboardSideNavComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardSideNavModule { }
