import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskaddingRoutingModule } from './taskadding-routing.module';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskaddingComponent } from './taskadding.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActonPlanFollowersModule } from 'src/app/actionPlans/acton-plan-followers/acton-plan-followers.module';
import {DatePipe} from '@angular/common';
import { CommoAlertModule } from 'src/app/actionPlans/commo-alert/commo-alert.module';

@NgModule({
  declarations: [TaskaddingComponent],
  imports: [
    FormsModule, 
    ReactiveFormsModule,
    CommonModule,
    TaskaddingRoutingModule,
    NgbModalModule,
    ActonPlanFollowersModule,
    CommoAlertModule
  ],
  exports: [TaskaddingComponent],
  entryComponents: [TaskaddingComponent],
  providers: [NgbActiveModal,DatePipe]

})
export class TaskaddingModule { }
