import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SkipSelf, TemplateRef, ViewChild, ɵConsole } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertifyService } from 'src/app/Providers/alertify/alertify.service';
import { NPApi } from 'src/app/Providers/NP-Api';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Common } from 'src/app/common';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IDayCalendarConfig, DatePickerComponent } from "ng2-date-picker";

declare var require: any;
const userprofileimageurl = environment.ImageUrl1;
@Component({
  selector: 'app-create-action-plan',
  templateUrl: './create-action-plan.component.html',
  styleUrls: ['./create-action-plan.component.scss'],

})
export class CreateActionPlanComponent implements OnInit, AfterViewInit {
  userInfo: any;
  @ViewChild('pop') poptemplateRef: TemplateRef<any>;
  // @HostListener('document:click', ['$event'])
  // clickout(event) {
  //   if(this.eRef.nativeElement.contains(event.target)) {
  //     console.log("clicked inside");
  //   } else {
  //     console.log("clicked outside",this.modaltype);
  //       if(this.modaltype!="H" && this.modaltype!="HN" && this.modaltype !=""){
  //         this.edittopvaluActionPlan(this.poptemplateRef,'H','Are you sure you want to close this window?');
  //       }     
  //   }
  // }
  type = "";
  @Input() actionPlantype: any;
  @Input() editActionPlanArray?: any;
  @Input() editActionPlanId?: any = 0;
  apcid: any;
  statusValue = '1';
  ShowAvgs: boolean = true;
  actionPlanArray: any = [];
  taskid: any = 0;
  actionPlantypeTask: any = "A";
  datePickerConfig: any;
  // openOnFocus: false;
  APsection:any='';
  constructor(private npSrv: NPApi, private router: Router,
    private spinner: NgxSpinnerService, private alertify: AlertifyService, private modalService: NgbModal,private eRef: ElementRef,
    private fb: FormBuilder, private ngbActiveModal: NgbActiveModal, private cd: ChangeDetectorRef, public comm: Common,) {
    //this.targetDate = moment(new Date()).add(1, 'M').format('YYYY-MM-DD');
    this.targetDate = moment(new Date()).add(1, 'M').format('MM/DD/YYYY');
    this.datePickerConfig = {
      //...this.datePickerConfig,
      format: 'MM/DD/YYYY',
      openOnFocus: false

    }

  }
  createAction = false;
  createActionDdl = false;
  actionPlanNames: any;
  selectedDevice = "";
  actionPlanTitle = "";
  actionPlanValuesFromService: any[] = []
  actionId = 0;
  targetDate: any;
  createdDate: Date;
  followersId: any[] = [];
  followers: any[] = [];
  @Output() onClose = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  selectedFile: any = [];
  selectedImgs: any = [];
  selectedFileData: any = null;
  modalReference: any;
  taragetValue = 0;
  storeId = localStorage.getItem("store_id")
  monthYear = localStorage.getItem("monthYear");
  actionPlanActualValue = 0;
  startValue = 0;
  statusValueId = '1';
  attachCount = 0;
  taskCount = 0;
  gradeActionPlan: string = '';
  approveId = 0;
  approveStatus = '';
  actionPlanCreatedId = 0;
  approvedDate: any;
  MonthsAvgValue = '';
  avgValueSelected = '6'
  editflag = 'N';
  iniassign: any = '';
  inifollows: any = [];
  targetValueFormula: any;
  isLoading = false;
  Averages: any = [];
  Edittopvalue: boolean = false;
  units: any = '';
  apActualValue: any;

  ngOnInit(): void {
    const pipe = new DatePipe('en-US');
    this.getActionPlansids();
    ///this.targetDate = new Date().toISOString().substring(0, 10);;
    //this.targetDate.setMonth(this.targetDate.getMonth() + 1);
    //this.targetDate.toISOString().substring(0, 10);

    //console.log(this.targetDate, 'vvv');

    this.createdDate = new Date();
    if(this.editActionPlanId != 0){
      this.editPopupData();
    }
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    console.log(this.userInfo, 'vv');
    // this.addActionPlansForm = this.fb.group({
    //   targetDate: [pipe.transform(this.targetDate, 'MM/DD/YY')],
    //   // createdDate: [''],
    //   statusValue: ['1'],
    // }
    // );
   // this.targetDate = pipe.transform(this.targetDate, 'MM/DD/YY');
    this.statusValue = '1';
    if (this.actionPlantype == 'G') {
      this.createAction = true;
      this.createActionDdl = false;
      this.actionPlanTitle = this.editActionPlanArray.sectionname;
      console.log('AP title:'+this.actionPlanTitle);
      console.log(this.actionPlanNames, this.editActionPlanArray, 'dfgbfgbfgbf');
      this.getActionPlanValues(this.editActionPlanArray.section, this.editActionPlanArray.sectionname);
      setTimeout(() => {
        // this.getFormulaForActionPlans();
      }, 1000);
    }
    this.storeId = localStorage.getItem("store_id")
    this.monthYear = localStorage.getItem("monthYear");
    this.Averages = [{
      AvgId: 3, AvgName: '3 Month Average'
    },
    { AvgId: 6, AvgName: '6 Month Average' },
    { AvgId: 9, AvgName: '9 Month Average' }
    ]
 this.npSrv.getActionPlanActualValueObs().subscribe(res => {
      console.log(res, 'bo')
      this.apActualValue = res
    })

  }

  closeModal() {
    //console.log('hi', this.modalRef);
    this.onClose.emit();
    this.ngbActiveModal.close();
  }

  ngOnChanges(): void {
    if (this.actionPlantype == 'E') {
      if(this.editActionPlanId == 0){
      //console.log(this.editActionPlanArray, 'vv');
      if (this.editActionPlanArray[0] != undefined && this.editActionPlanArray[0] != null) {
        //this.getActionPlanValues('', this.editActionPlanArray[0].aptitle);
        //this.targetDate = moment(this.editActionPlanArray[0].aptargetenddate).format('YYYY-MM-DD');
        this.targetDate = moment(this.editActionPlanArray[0].aptargetenddate).format('MM/DD/YYYY');
        setTimeout(() => {
          this.getFormulaForActionPlans();
          // this.addActionPlansForm.patchValue({
          //   targetDate: [this.targetDate],
          //   // createdDate: [''],
          //   statusValue: this.editActionPlanArray[0].activestatus,
          // })

        }, 2000);
        this.statusValue = this.editActionPlanArray[0].activestatus;
        this.units = this.editActionPlanArray[0].npunits;
        this.actionPlanTitle = this.editActionPlanArray[0].aptitle;
        this.actionId = this.editActionPlanArray[0].apid;
        this.taragetValue = this.editActionPlanArray[0].aptargetvalue;
        this.targetValueFormula = this.editActionPlanArray[0].aptargetvalue;
        this.monthYear = this.editActionPlanArray[0].apmonthyear;
        this.storeId = this.editActionPlanArray[0].apstoreid;
        this.actionPlanActualValue = this.editActionPlanArray[0].apactualvalue;
        this.startValue = this.editActionPlanArray[0].apstartvalue;
        this.approveId = this.editActionPlanArray[0].apapprovedid;
        this.approveStatus = this.editActionPlanArray[0].approvestatus;
        this.actionPlanCreatedId = this.editActionPlanArray[0].apcreatedId;
        this.approvedDate = this.editActionPlanArray[0].approveddate;
        this.MonthsAvgValue = this.editActionPlanArray[0].avgvalue;
        this.avgValueSelected = this.editActionPlanArray[0].monthsavg != null ? this.editActionPlanArray[0].monthsavg : '6'
        this.attachCount = this.editActionPlanArray[0].attachscount != 0 ? this.editActionPlanArray[0].attachscount : ''
        this.taskCount = this.editActionPlanArray[0].taskcount != 0 ? this.editActionPlanArray[0].taskcount : ''
        this.followers = this.convertFollowersXml(this.editActionPlanArray[0].Followers);
        this.assignee = this.convertAssignerXml(this.editActionPlanArray[0].AssignedTo);
        this.assignee != undefined ? this.assignee : this.assignee = [];
        this.iniassign = (this.assignee != undefined) ? this.assignee.sc_id : 0;
        this.approvedBy = this.convertAssignerXml(this.editActionPlanArray[0].approvedBy);
        this.approvedBy != undefined ? this.approvedBy : this.approvedBy = [];
        this.taragetValue = this.setValue(this.taragetValue);
        this.netIncrease = this.editActionPlanArray[0].apresultvalue != null ? this.editActionPlanArray[0].apresultvalue : 0;
        this.statusValueId = this.editActionPlanArray[0].activestatus;
        console.log(this.targetDate);


        this.followers != undefined ? this.followers : this.followers = [];
        this.inifollows = this.followers;

        //console.log(this.followers, this.assignee, 'sra');
        this.createAction = true;
        this.createActionDdl = false;
      } else {

      }
    }


    } else {
      this.createActionDdl = true;
    }

    this.cd.detectChanges();
  }
  ngAfterViewInit() {

  }

  getActionPlans(id) {
    this.type = (this.comm.selectedItem == 1) ? 'cash' : (this.comm.selectedItem == 6) ? 'revenue' : (this.comm.selectedItem == 3) ? 'expense' : (this.comm.selectedItem == 4) ? 'compensation' : (this.comm.selectedItem == 5) ? 'profit' : (this.comm.selectedItem == 7) ? 'staffing' : '';
    console.log(this.type);
    let obj = { id: id, expression: '', searchflag: '', userid: this.userInfo.id, monthyear: '', storeid: localStorage.getItem('store_id'), deptid: '', type: this.type }
    this.isLoading = true;
    this.npSrv.getActionPlanDashboard(obj).subscribe((resActionPlans: any) => {
      //this.isLoading = false;
      this.isLoading = false;
      if (resActionPlans.status == 200) {
        this.editActionPlanArray = resActionPlans.response;
        this.actionPlantype = 'E';
        console.log(this.editActionPlanArray);
        // for (let i = 0; i < this.actionPlanArray.length; i++) {
        //   this.convertAssignerXml(this.actionPlanArray[i].AssignedTo)
        // }

      }
    }, err => {
      //this.isLoading = false;
      this.isLoading = false;
    })

  }



  getActionPlanName() {
    //this.spinner.show();
    this.npSrv.GetAPLabelsJson().subscribe((resPlanNames: any) => {
      //this.spinner.hide();
      if (resPlanNames.length > 0) {
        this.actionPlanNames = JSON.parse(resPlanNames);
        var split = this.apids.split(',');
        this.actionPlanNames = this.actionPlanNames.filter(item => {
          item.children = item.children.filter(sbitem => {
            return split.indexOf(sbitem.id) == -1;
          })
          return item
        });
        console.log(this.actionPlanNames)
      }
    }, err => {
      //this.spinner.hide();
    })


  }

  convertStoresXml(stores) {
    const storeObj = [];
    if (stores != null || stores != "") {
      // let s = "<stores>" + stores + "</stores>";
      let res: any = [];
      let parseString = require('xml2js').parseString;
      parseString(stores, function (err, result) {
        //console.log(result);
        if (result != null) {
          res = result.Descriptions.Description;
        }
      });

      if (res) {
        for (var i = 0; i < res.length; i++) {
          storeObj.push({ 'ACPT_ID': res[i].ACPT_ID, 'ACPT_DESC': res[i].ACPT_DESC, 'DESC1': res[i].DESC1 })
        }
        ///console.log(storeObj);

        return storeObj;
      }
    }
  }

  convertFollowersXml(stores) {
    //console.log(stores);

    let storeObj = [];
    if (stores != null || stores != "") {
      // let s = "<stores>" + stores + "</stores>";
      let res: any = [];
      let parseString = require('xml2js').parseString;

      parseString(stores, function (err, result) {
        console.log(result);
        if (result != undefined) {
          if (result.Followers != undefined) {
            res = result.Followers.Follower;
            //console.log(result);
          }
        }
      });

      if (res) {

        for (var i = 0; i < res.length; i++) {
          storeObj.push({
            'sc_id': res[i].sc_id, 'sc_firstname': res[i].sc_firstname, 'sc_lastname': res[i].sc_lastname, 'sc_stores': res[i].sc_stores,
            'sc_title': res[i].sc_title, 'sc_profile_image': res[i].sc_profile_image, 'sc_status': res[i].sc_status
          })
        }

        ///console.log(storeObj);

        return storeObj;
      }
    }
  }
  convertAssignerXml(stores) {
    //console.log(stores);

    let storeObj = [];
    if (stores != null || stores != "") {
      // let s = "<stores>" + stores + "</stores>";
      let res: any = [];
      let parseString = require('xml2js').parseString;

      parseString(stores, function (err, result) {
        ///console.log(result);
        if (result != undefined) {
          if (result.Assignee != undefined) {
            res = result.Assignee.Assign;
          }
          if (result.Approved != undefined) {
            res = result.Approved.Approve;
          }
        }
      });

      if (res) {
        if (res.Followers != undefined) {

        }
        else {
          storeObj = res[0]
        }
        console.log(storeObj);

        return storeObj;
      }
    }
  }

  selectedValue: any = '';
  onChange(newValue) {
    this.selectedDevice = newValue.target.value;
    let selectedIndex: number = newValue.target["selectedIndex"];
    let action = newValue.target.options[selectedIndex].getAttribute("data-actionName");
    let title = newValue.target.options[selectedIndex].getAttribute("data-labelName");
    this.selectedValue = newValue.target.options[selectedIndex].getAttribute("data-labelValue");
    this.createActionDdl = false;
    this.createAction = true;
    //console.log(title);
    //this.actionPlanTitle = newValue.target.value;
    this.actionPlanTitle = title;
    this.getActionPlanValues(action, title);
    setTimeout(() => {
      this.getFormulaForActionPlans();
    }, 1500);


  }

  getActionPlanValues(action, actionColumnName) {
    console.log('action'+action);
    this.APsection=action.toLowerCase();
    ///this.spinner.show();
    if (action == "COMP") {
      action = "compensation";
    } else if (action.toLowerCase() == "gross") {
      action = "revenue ";
    }
    //let storeId = localStorage.getItem("store_id")
    // console.log(this.monthYear,'bb');
    const obj = { storeId: this.storeId, section: action, sectionvalue: actionColumnName, monthyear: this.monthYear }
    this.npSrv.getActionPlanVales(obj).subscribe((dataRes: any) => {
      //this.spinner.hide();
      console.log(dataRes);
      if (dataRes.status == 200) {
        this.actionPlanValuesFromService = dataRes.response;
        this.MonthsAvgValue = this.avgValueSelected == '3' ? this.actionPlanValuesFromService[0].threemavg : this.avgValueSelected == '6' ? this.actionPlanValuesFromService[0].sixmavg : this.actionPlanValuesFromService[0].ninemavg
        this.actionPlanActualValue = this.actionPlanValuesFromService[0].actualvalue;
        //this.startValue = this.actionPlanValuesFromService[0].startvalue != 0 ? this.actionPlanValuesFromService[0].startvalue : this.actionPlanValuesFromService[0].benchmark;
        this.startValue = this.actionPlanValuesFromService[0].startvalue;
        this.gradeActionPlan = this.actionPlanValuesFromService[0].grade;
        if (this.actionPlantype != "E") {
          let highval = 0;
          let lowval = 0;
          if (this.actionPlanValuesFromService[0].actualvalue < this.actionPlanValuesFromService[0].benchmark) {
            highval = this.actionPlanValuesFromService[0].benchmark;
            lowval = this.actionPlanValuesFromService[0].actualvalue;
          }
          else {
            lowval = this.actionPlanValuesFromService[0].benchmark;
            highval = this.actionPlanValuesFromService[0].actualvalue;
          }
          this.taragetValue = this.actionPlanValuesFromService[0].gradevalue == 'L' ? lowval : highval;
          this.targetValueFormula = this.taragetValue;
          // this.taragetValue = this.actionPlanValuesFromService[0].targetvalue != null ? this.actionPlanValuesFromService[0].targetvalue : 0;
          ///this.targetValueFormula = this.actionPlanValuesFromService[0].targetvalue != null ? this.actionPlanValuesFromService[0].targetvalue : 0;
          this.taragetValue = this.setValue(this.taragetValue);
          this.units = this.actionPlanValuesFromService[0].units;
        }
      } else {
        //this.actionPlanValuesFromService = []
      }

    }, err => {
      //this.spinner.hide();
    })
  }

  onAverageSelected(ev) {
    this.avgValueSelected = ev;
    this.MonthsAvgValue = this.avgValueSelected == '3' ? this.actionPlanValuesFromService[0].threemavg : this.avgValueSelected == '6' ? this.actionPlanValuesFromService[0].sixmavg : this.actionPlanValuesFromService[0].ninemavg
  }

  onEditSubmit(temp, flag, content) {
    this.alertContent = content;
    this.actionPlantypeAlert = flag;
    if (this.actionPlantype == 'E') {
      if (this.Edittopvalue) {
        // if (confirm('Are You Want to Create a new action plan with this change?') == false) {
        //   this.editflag = 'N';
        // }
        // else {
        //   this.editflag = 'Y'
        // }
        this.ngbActiveModal = this.modalService.open(temp, { size: 'sm', backdrop: 'static', windowClass: 'Center-aligned' });
      } else {
        this.onSubmit();
      }
    }
  }

  alertContent = "";
  actionPlantypeAlert = '';
  onSubmit() {

    // console.log(this.editActionPlanArray[0].monthsavg,this.avgValueSelected);
    // console.log(this.editActionPlanArray[0].aptargetvalue,this.taragetValue);
    // console.log(this.editActionPlanArray[0].apapprovedid.toString(), approve);
    // console.log(this.iniassign,assigned)
    // console.log(this.followers,this.inifollows)
    let followId: any[] = [];
    if (this.followers.length != 0) {
      for (let k = 0; k < this.followers.length; k++) {
        followId.push(this.followers[k].sc_id)
      }
    }
    //console.log(this.addActionPlansForm.value)
    let uids = followId.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) == index;
    });
    if (this.actionId == 0) {
      let approvedBy
      let assigned = this.assignee != undefined ? this.assignee.sc_id : 0;
      if ((this.userInfo.id != this.actionPlanCreatedId && this.approveId != this.userInfo.id && this.approveStatus == 'N' && this.approveId != 0)) {
        approvedBy = this.approvedBy != undefined ? this.approvedBy.sc_id : 0;
      } else {
        approvedBy = this.approvedBy != undefined ? this.approvedBy.sc_id : 0;
      }
      let obj = {
        action: "A", AP_Title: this.actionPlanTitle, AP_CurrentValue: 0, AP_StartValue: this.startValue, AP_TargetValue: this.targetValueFormula, AP_StartDate: this.createdDate, AP_TargetEnddate: this.targetDate, AP_CreatedBy: this.userInfo.id, AP_FollowersIds: uids.join(","), AP_AssignedId: assigned != undefined ? assigned : 0, AP_StoreId: localStorage.getItem("store_id"), AP_ResultValue: this.netIncrease, AP_MonthYear: localStorage.getItem("monthYear"), AP_ActualValue: this.actionPlanActualValue, AP_Active_Status: this.statusValueId, AP_ApprovedBy: approvedBy, AP_Grade: this.gradeActionPlan, AP_AvgValue: this.MonthsAvgValue, AP_AvgMonths: this.avgValueSelected, AP_EditFlag: this.editflag, err_no: '',
        err_no1: ''
      }
      this.npSrv.addActtionPlan(obj).subscribe((dataRes: any) => {
        console.log(dataRes);
        if (dataRes.status == 200) {
          //this.alertify.success(dataRes.response)
          if (this.selectedFile.length > 0) {
            this.apcid = dataRes.response.result;
            this.SaveAttachments(dataRes.response.response);
          }

          if (this.taskAdding.length > 0) {
            this.apcid = dataRes.response.result;
            this.saveTask(this.taskAdding);

          }

          let param: any = { obj: "yes", obj1: 123 }
          //this.modalService.
          this.ngbActiveModal.close();
       //   this.npSrv.sendNotification(obj);
          this.onAdd.emit(param)
          this.alertify.success(dataRes.response.response)

        } else {
          this.alertify.warning(dataRes.error)
        }
      }, err => {
        this.alertify.error("Some Net Issue Please try after some time!");
      })
    } else {
      let approvedBy;
      let assigned = this.assignee != undefined ? this.assignee.sc_id : 0;
      if ((this.userInfo.id != this.actionPlanCreatedId && this.approveId != this.userInfo.id && this.approveStatus == 'N' && this.approveId != 0)) {
        approvedBy = this.approvedBy != undefined ? this.approvedBy.sc_id : 0;
      } else {
        approvedBy = this.approvedBy != undefined ? this.approvedBy.sc_id : 0;
      }
      let obj = {
        action: "U", AP_Id: this.actionId, AP_Title: this.actionPlanTitle, AP_CurrentValue: 0, AP_StartValue: this.startValue, AP_TargetValue: this.targetValueFormula, AP_StartDate: this.createdDate, AP_TargetEnddate: this.targetDate, AP_CreatedBy: this.actionPlanCreatedId,
        AP_FollowersIds: uids.join(","), AP_AssignedId: assigned != undefined ? assigned : 0, AP_StoreId: localStorage.getItem("store_id"), AP_ResultValue: this.netIncrease, AP_MonthYear: localStorage.getItem("monthYear"), AP_Status: 'Y', AP_ActualValue: this.actionPlanActualValue, AP_Active_Status: this.statusValueId, AP_ApprovedBy: approvedBy, AP_EditFlag: this.editflag, AP_Grade: this.gradeActionPlan,
        AP_AvgValue: this.MonthsAvgValue, AP_AvgMonths: this.avgValueSelected, err_no: '',
        err_no1: ''
      }
      this.npSrv.addActtionPlan(obj).subscribe((dataRes: any) => {
        console.log(dataRes);
        if (dataRes.status == 200) {
          //this.alertify.success(dataRes.response)
          if (this.selectedFile.length > 0) {
            this.apcid = this.actionId;
            this.SaveAttachments(dataRes.response);
          }
          else {
            let param: any = { obj: "yes", obj1: 123 }
            this.modalService.dismissAll();
       //     this.npSrv.sendNotification(obj);
            this.onAdd.emit(param)
            if (this.editflag == "C" || this.editflag == "X")
              this.alertify.success('Action Plan updated successfully')
            else {
              this.alertify.success('Action Plan updated successfully')
            }
          }

        } else {
          this.alertify.warning(dataRes.error)
        }
      }, err => {
        this.alertify.error("Some Net Issue Please try after some time!");
      })

    }
  }


  openActionPlanFollowers(content, type) {
    console.log('ji', type);

    /// type == "A" ? this.type = 'A' : type = 'F' ? this.type = 'F' : this.type = "AP"
    if (type == 'A') {
      this.type = 'A';
    } else if (type == "F") {
      this.type = "F";
    }
    else {
      this.type = 'AP';
    }
    this.ngbActiveModal = this.modalService.open(content, { size: 'sm' });
  }
  assignee: any;
  approvedBy: any;
  assigndate: boolean = true;
  changeImg(data) {
    //this.followers = []
    console.log(data);
    if (this.type == "F") {
      if (data.followerIds.length != 0) {
        for (let i = 0; i < data.followerIds.length; i++) {
          this.followersId.push(data.followerIds[i])
        }
      }

      console.log(data.followers);

      if (data.followers.length != 0) {
        this.followers = [];
        for (let i = 0; i < data.followers.length; i++) {
          this.followers.push(data.followers[i]);
          //this.followersId.push(data.followers[i].sc_id)
          //console.log(this.followersId);

        }
      }



      if (data.unFollowers.length != 0) {
        for (let j = 0; j < data.unFollowers.length; j++) {
          for (let i = 0; i < this.followers.length; i++) {
            if (this.followers[i].sc_id == data.unFollowers[j].sc_id) {
              this.followers.splice(i, 1)
            }
            ///this.followersId.splice(this.followersId.indexOf(data.unFollowers[i].sc_id), 1)
          }
        }
      }
    }

    this.type == 'A' ? this.assignee = data.assignee : this.assignee;
    // if(this.type == 'A'){
    //   this.editActionPlanArray[0].npassignedDate = null;
    // }
    this.assigndate = (this.type == 'A') ? false : true;
    this.approvedBy = this.type == 'AP' ? this.approvedBy = data.approveBy : this.approvedBy;
    if (this.type == 'AP') {
      if (this.actionPlantype == 'E') {
        this.approveStatus = "N";
        this.approveId = this.approvedBy.sc_id;
        if (this.approveId == this.userInfo.id) {
          this.approveStatus = "Y";
        }
      }
    }
    //this.type == 'A' ? this.followersId = this.followersId.push(this.followers.sc) : this.assignee;
    if (this.type == 'A') {
      if (data.followers.length != 0) {
        for (let i = 0; i < data.followers.length; i++) {
          this.followersId.push(data.followers[i].sc_id)
        }
      }
    }

    console.log(this.followers, 'mo');
    this.ngbActiveModal.dismiss();
  }

  closeModalf(data) {
    this.ngbActiveModal.close();
  }
  closeModalattach(data) {
    this.attachCount = data;
    this.ngbActiveModal.close();
  }
  formula: any = '';
  netIncrease: any;
  toFixedValue = 0;
  netIncreaseLoader = false;
  getFormulaForActionPlans() {
    this.netIncrease = 0;
    let obj = {
      storeId: this.storeId,
      section: this.actionPlanTitle,
      "monthyear": this.monthYear
    }
    //     let obj = {
    //       "storeId": "57167",
    //       "section": "# New Retail Units",
    //       "monthyear": "201812"
    // dataFormula.response[0].formula_fixed
    //     }
    this.netIncreaseLoader = true;
    this.npSrv.getformula(obj).subscribe((dataFormula: any) => {
      //console.log('APdata'+JSON.stringify(dataFormula));
      this.formula = dataFormula.response != undefined ? dataFormula.response[0].formula : null;
      let AP_StartValue: any = this.startValue != null ? this.startValue : 0;
      this.toFixedValue = dataFormula.response != undefined ? dataFormula.response[0].formula_fixed : 0;
      let netIncrease = 0;
      let diff = (this.targetValueFormula - parseFloat(AP_StartValue));
      if (this.formula != null) {
        var map = new Map();

        var result = dataFormula.response.map(o => {
          Object.keys(o).forEach(e => {
            window[e] = + o[e];
          });
        });


        netIncrease = eval(this.formula);
        //console.log('result', this.netIncrease.toFixed(2));
      } else {
        if (this.actionPlanTitle != '# New Fleet Units') {
          netIncrease = diff;
        } else {
          netIncrease;
        }
      }
      console.log(netIncrease, 'll');
      netIncrease = isNaN(netIncrease) ? 0 : netIncrease;
      this.netIncrease = netIncrease.toFixed(this.toFixedValue);
      console.log(this.netIncrease, diff, AP_StartValue, '');
      this.netIncreaseLoader = false;





    }, err => {

    })
  }
  valuechange(value) {
    ///let i = value.substring(1);
    //console.log(parseInt(i));
    console.log(value);
    //this.getFormulaForActionPlans();
    if (this.formula == "") {
      this.getFormulaForActionPlans();
    }
    this.netIncreaseLoader = true;

    setTimeout(() => {
      this.targetValueFormula = this.toNumber(this.taragetValue)

      this.taragetValue
      //= parseInt(i);
      let netIncrease = 0;
      let AP_StartValue = 0;
      if (this.actionPlanValuesFromService[0] != undefined) {
        AP_StartValue = this.actionPlanValuesFromService[0].startvalue != null ? this.actionPlanValuesFromService[0].startvalue : 0;
      } else {
        AP_StartValue = this.startValue;
      }
      let diff = (this.targetValueFormula - AP_StartValue);
      if (this.formula != null && this.formula != "") {
        netIncrease = eval(this.formula);
      } else {
        netIncrease = diff;
      }
      console.log(netIncrease);

      this.netIncrease = netIncrease.toFixed(this.toFixedValue);
      console.log(this.netIncrease, this.formula, 'k');
      this.netIncreaseLoader = false;
    }, 2000);



  }

  openActionPlanAttachments(content) {
    console.log(this.selectedImgs);
    this.ngbActiveModal = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
  }

  openActionPlanTask(content) {
    this.actionPlantype = "A";
    this.ngbActiveModal = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
  }
  onCloseTask(data) {
    this.taskCount = data.length;
    this.taskAdding = data;
    // console.log(this.taskAdding, 'hh');

    this.ngbActiveModal.close();
  }

  ApAttachments(data) {
    this.selectedFile = data[0].data;
    this.selectedImgs = data;
    this.attachCount = this.selectedFile.length;
    this.ngbActiveModal.dismiss();
  }

  SaveAttachments(res) {
    const input = new FormData();
    input.append('action', 'A');
    input.append('apa_acp_id', this.apcid);
    input.append('apa_file_name', "testimg.png");
    input.append('apa_userid', this.userInfo.id);
    input.append('apa_updatedUid', this.userInfo.id);
    // input.append('file', this.selectedFile);
    for (let i = 0; i < this.selectedFile.length; i++) {
      input.append('file', this.selectedFile[i]);
    }
    this.npSrv.addActtionPlanAttachments(input).subscribe((dataRes: any) => {
      console.log(dataRes);
      if (dataRes.status == 200) {
        //this.alertify.success(dataRes.response)
        let param: any = { obj: "yes", obj1: 123 }
        this.modalService.dismissAll();
        this.onAdd.emit(param)
        this.alertify.success(res)
      } else {
        this.alertify.warning(dataRes.error)
      }
    })
  }
  taskAdding: any[] = [];
  onAddUpdateTask(data) {
    console.log(data);
    if (this.actionId != 0) {
      if (data.status == 200) {
        this.alertify.success(data.response)
      } else {
        this.alertify.warning(data.error)
      }
    }
    this.ngbActiveModal.close();

  }

  onOptionsSelected(ev) {
    this.statusValueId = ev.target.value;
  }

  saveTask(data) {
    //console.log(data);

    let obj: any;
    for (let i = 0; i < data.length; i++) {
      obj = {
        ta_Id: "0",
        ta_Ticket: "1",
        ta_U_UId: this.userInfo.id,
        ta_TS_Id: 1,
        ta_acp_id: this.apcid,
        ta_TaskName: data[i].ta_TaskName,
        ta_TaskDetails: "",
        ta_Status: "Y",
        ta_DealerId: localStorage.getItem('store_id'),
        ta_Priority: data[i].ta_Priority,
        ta_TaskType: data[i].ta_TaskType,
        ta_Loginfrom: "D",
        ta_ParentId: 0,
        AST_FollowesIds: data[i].Followers,
        Assignto_uid: data[i].ta_Assignby_U_UId,
        ta_DueDate: data[i].ta_DueDate,
        action: "A"
      }

      console.log(obj, 'mo');
      this.npSrv.addAndUpdateTaskAssign(obj).subscribe((resTask: any) => {
        if (resTask.status == 200) {

          //this.alertify.success(resTask.response)

        } else {
          //this.alertify.warning(resTask.error)
        }
      }, err => {
        console.log(err);

      })
    }

    this.ngbActiveModal.dismiss();

  }


  //approve
  approveActionPlanById() {
    let obj = {
      Ap_Id: this.actionId
    }
    this.npSrv.approveActionPlan(obj).subscribe((approveData: any) => {
      if (approveData.status == 200) {
        this.alertify.success(approveData.response);
        this.approveStatus = "Y";
      } else {
        this.alertify.warning(approveData.error)
      }
    }, err => {

    })
  }


  toFixedSpecial(number) {
    var n = number
    if (n != null) {
      var parts = n.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); if (parts[1] > 0)
        return parts.join('.');
      else
        return parts[0];
    }
  }
  value: any;
  flag = '';
  setValue(value) {
    if (value != null) {
      let newVal = this.toFixedSpecialAddSign(value)
      if (this.units.includes("%")) {
        this.flag = '%';
      } else if (this.units.includes("D")) {
        this.flag = "$";
      } else {
        this.flag = '';
      }
      newVal = this.flag == "%" ? newVal + this.flag : this.flag == "$" ? this.flag + newVal : newVal;
      //newVal =  + newVal;
      console.log(newVal, 'kk');

      if (newVal) {
        this.value = newVal;
        return this.value;

      }
    }
    return this.value = 0;
  }

  toFixedSpecialAddSign(newVal) {
    var n = newVal
    if (n != null) {
      if (n == ".") {
        return "0."
      }
      else if (n.length == 2) {
        return n;
      } else {
        var parts = n.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); if (parts[1] > 0)
          return parts.join('.');
        else
          return parts[0];
      }
    }
  }

  toNumber(val) {
    // let valArr = val.split('');
    // let valFiltered = valArr.filter(x => !isNaN(x))
    // let valProcessed = valFiltered.join('')
    let valProcessed = val.includes('%') ? val.substring(0, val.length - 1) : val.includes('$') ? val.split('$')[1] : val;
    console.log(parseFloat(valProcessed.replace(/,/g, '')), 'mm');
    return parseFloat(valProcessed.replace(/,/g, ''));
  }

  modaltype:any="";
  onCloseAlert(type, temp) {
    console.log(type);
    this.modaltype = type;
    // if (type == "Y") {
    //   this.editflag = 'Y';
    //   this.ngbActiveModal.close();
    //   this.onSubmit();
    // } else if(type == "N") {
    //   this.editflag = 'N';
    //   this.ngbActiveModal.close();
    //   this.onSubmit();
    // }else
    if (type == "Y") {
      this.Edittopvalue = true;
      this.ngbActiveModal.close();
    } else if (type == "N") {
      this.ngbActiveModal.close();
    } else if (type == "E") {
      this.onClose.emit();
      this.ngbActiveModal.close();
    } else if (type == "H") {
      this.onClose.emit();
      setTimeout(() => {
        this.modaltype = "";
      }, 500);
      this.ngbActiveModal.close();
    } else if (type == "HN") {
      this.ngbActiveModal.close();
      setTimeout(() => {
        this.modaltype = "";
      }, 500);
    } else {
      this.editflag = type;
      this.ngbActiveModal.close();
      this.onSubmit();
    }
  }

  onClosemsg() {
    this.ngbActiveModal.close();
  }

  // openActionPlanmsgs(content) {
  //   console.log('uihhjh');
  //   this.ngbActiveModal = this.modalService.open(content, { size: 'sm', backdrop: 'static', });
  // }

  openActionPlanmsgs(content) {
    //console.log('uihhjh');
    this.comm.Msgconv_id = this.actionId;
    this.comm.title = "actionplan";
    // this.ngbActiveModal = this.modalService.open(content, { size: 'sm', backdrop: 'static', });
    this.router.navigate(['messenger', this.actionId]);
    this.modalService.dismissAll();
  }

  changed(ev) {
    console.log(ev);
    let action = "";
    let title = "";
    if (ev != '') {
      this.actionPlanNames.forEach(section => {
        section.children.forEach(element => {
          if (element.id == ev) {
            console.log(element);
            action = section.text;
            title = element.text;
            this.selectedValue = element.id;
          }
        });
      });
      this.createActionDdl = false;
      this.createAction = true;
      //console.log(title);
      //this.actionPlanTitle = newValue.target.value;
      this.actionPlanTitle = title != "" ? title : this.actionPlanTitle;
      console.log('wfdege', this.selectedValue);
      this.getActionPlanValues(action, title);
      setTimeout(() => {
        this.getFormulaForActionPlans();
      }, 1500);
    }
    if (this.actionPlantype == 'G' && this.selectedValue == '') {
      this.actionPlanNames.forEach(section => {
        section.children.forEach(element => {
          if (element.text == this.actionPlanTitle) {
            this.selectedValue = element.id;
            this.cd.detectChanges();
          }
        });
      });
    }
  }

  edittopvaluActionPlan(temp, flag, content) {
    this.alertContent = content;
    this.actionPlantypeAlert = flag;
    this.ngbActiveModal = this.modalService.open(temp, { size: 'sm', backdrop: 'static', windowClass: 'Center-aligned' });

  }


  apids: any = "";
  getActionPlansids() {
    let obj = { storeid: localStorage.getItem('store_id') }
    this.isLoading = true;
    this.npSrv.getActionPlanids(obj).subscribe((resActionPlans: any) => {
      //this.isLoading = false;
      this.isLoading = false;
      if (resActionPlans.status == 200) {
        this.apids = resActionPlans.response[0].apids;
        this.getActionPlanName();
        console.log(this.actionPlanArray);
      }
    }, err => {
      //this.isLoading = false;
      this.isLoading = false;
    })

  }


  changeOutput(content) {
    // this.getActonPlanTask()
    this.ngbActiveModal.dismiss();
    this.ngbActiveModal = this.modalService.open(content, {
      size: 'lg', backdrop: 'static', keyboard: true,
    });
  }

  statusChange(number) {
    this.statusValueId = number;
    //console.log(this.statusValueId, 'kk');

  }

  editPopupData(){
    let obj = { id: this.editActionPlanId, expression: '' }
    //this.alertContent = alertContent;
    this.isLoading = true;
    this.npSrv.getActionPlanDashboard(obj).subscribe((resActionPlans: any) => {
      if (resActionPlans.status == 200) {
        this.editActionPlanArray = resActionPlans.response;
//        console.log(this.actionPlanArray);
if (this.editActionPlanArray[0] != undefined && this.editActionPlanArray[0] != null) {
        //this.getActionPlanValues('', this.editActionPlanArray[0].aptitle);
        //this.targetDate = moment(this.editActionPlanArray[0].aptargetenddate).format('YYYY-MM-DD');
        this.targetDate = moment(this.editActionPlanArray[0].aptargetenddate).format('MM/DD/YYYY');
        setTimeout(() => {
          this.getFormulaForActionPlans();
          // this.addActionPlansForm.patchValue({
          //   targetDate: [this.targetDate],
          //   // createdDate: [''],
          //   statusValue: this.editActionPlanArray[0].activestatus,
          // })

        }, 2000);
        this.statusValue = this.editActionPlanArray[0].activestatus;
        this.units = this.editActionPlanArray[0].npunits;
        this.actionPlanTitle = this.editActionPlanArray[0].aptitle;
        this.actionId = this.editActionPlanArray[0].apid;
        this.taragetValue = this.editActionPlanArray[0].aptargetvalue;
        this.targetValueFormula = this.editActionPlanArray[0].aptargetvalue;
        this.monthYear = this.editActionPlanArray[0].apmonthyear;
        this.storeId = this.editActionPlanArray[0].apstoreid;
        this.actionPlanActualValue = this.editActionPlanArray[0].apactualvalue;
        this.startValue = this.editActionPlanArray[0].apstartvalue;
        this.approveId = this.editActionPlanArray[0].apapprovedid;
        this.approveStatus = this.editActionPlanArray[0].approvestatus;
        this.actionPlanCreatedId = this.editActionPlanArray[0].apcreatedId;
        this.approvedDate = this.editActionPlanArray[0].approveddate;
        this.MonthsAvgValue = this.editActionPlanArray[0].avgvalue;
        this.avgValueSelected = this.editActionPlanArray[0].monthsavg != null ? this.editActionPlanArray[0].monthsavg : '6'
        this.attachCount = this.editActionPlanArray[0].attachscount != 0 ? this.editActionPlanArray[0].attachscount : ''
        this.taskCount = this.editActionPlanArray[0].taskcount != 0 ? this.editActionPlanArray[0].taskcount : ''
        this.followers = this.convertFollowersXml(this.editActionPlanArray[0].Followers);
        this.assignee = this.convertAssignerXml(this.editActionPlanArray[0].AssignedTo);
        this.assignee != undefined ? this.assignee : this.assignee = [];
        this.iniassign = (this.assignee != undefined) ? this.assignee.sc_id : 0;
        this.approvedBy = this.convertAssignerXml(this.editActionPlanArray[0].approvedBy);
        this.approvedBy != undefined ? this.approvedBy : this.approvedBy = [];
        this.taragetValue = this.setValue(this.taragetValue);
        this.netIncrease = this.editActionPlanArray[0].apresultvalue != null ? this.editActionPlanArray[0].apresultvalue : 0;
        this.statusValueId = this.editActionPlanArray[0].activestatus;
        console.log(this.targetDate);


        this.followers != undefined ? this.followers : this.followers = [];
        this.inifollows = this.followers;

        //console.log(this.followers, this.assignee, 'sra');
        this.createAction = true;
        this.createActionDdl = false;
      } else {

      }


    } else {
      this.createActionDdl = true;
    }

    this.cd.detectChanges();
      
    }, err => {
      this.isLoading = false;
    })
  }


}
