import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ActionPlanAttachComponent } from './action-plan-attach.component';
import { NgbActiveModal, NgbModalModule, } from '@ng-bootstrap/ng-bootstrap';
import { ImagecropModule } from '../imagecrop/imagecrop.module';
import { LoaderAnimationModule } from 'src/app/views/loader-animation/loader-animation.module';


@NgModule({
  declarations: [ActionPlanAttachComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgbModalModule,
    ImagecropModule,
    LoaderAnimationModule
  ],
  exports: [ActionPlanAttachComponent],
  entryComponents: [ActionPlanAttachComponent],
  providers: [NgbActiveModal]
})
export class ActionPlanAttachModule { }
