import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderStripComponent } from './common-header-strip.component';
import { LimitCharsPipeModule } from '../pipes/limit-chars/LimitCharsPipeModule';
import { DropdownlistModule } from '../dropdownlist/dropdownlist.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinechatComponent } from '../linechat/linechat.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ChartsModule } from 'ng2-charts';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommoAlertModule } from '../actionPlans/commo-alert/commo-alert.module';
import { NgbActiveModal, NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderAnimationModule } from 'src/app/views/loader-animation/loader-animation.module';


@NgModule({
  declarations: [CommonHeaderStripComponent, LinechatComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    LimitCharsPipeModule,
    FormsModule,
    SlickCarouselModule,
    ChartsModule,
    DropdownlistModule,
    CommoAlertModule,
    NgbModalModule,
    LoaderAnimationModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [CommonHeaderStripComponent]
  ,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [NgbActiveModal]
})
export class CommonHeaderStripModule { }
