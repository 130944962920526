import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-commo-alert',
  templateUrl: './commo-alert.component.html',
  styleUrls: ['./commo-alert.component.scss']
})
export class CommoAlertComponent implements OnInit {
  @Output() onCloseAlert = new EventEmitter();
  @Input() alertContent: any;
  @Input() alertType: any;
  constructor() { }

  ngOnInit(): void {
  }
  deleteaction() {
    this.onCloseAlert.emit('D')
  }
  closeModal() {
    this.onCloseAlert.emit('')
  }
  achievedActon() {
    this.onCloseAlert.emit("A")
  }
  editActon() {
    this.onCloseAlert.emit("E")
  }
  okAction(type) {
    if(this.alertType == 'S')
    this.onCloseAlert.emit("Y");
    else if(type == 'A'){
      this.onCloseAlert.emit("C");
    } else if(type == 'C'){
      this.onCloseAlert.emit("X");
    } else if(type == 'E'){
      this.onCloseAlert.emit("E");
    }
  }
  cancelActon() {
    this.onCloseAlert.emit("N")
  }
  complete() {
    this.onCloseAlert.emit('C')
    }
    Archive(){
      this.onCloseAlert.emit("A")
      
      }
      ok(){   

        this.onCloseAlert.emit("A")
    
      }

      confirm(type){
        if(type == 'S')
        this.onCloseAlert.emit("H");
        else
        this.onCloseAlert.emit("HN");
      }
}
