import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagecropComponent } from './imagecrop.component';


@NgModule({
  declarations: [ImagecropComponent],
  imports: [
    CommonModule,
    ImageCropperModule
  ],
  exports: [ImagecropComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImagecropModule { }
