import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nplogo',
  templateUrl: './nplog.component.svg',
  styleUrls: ['./nplogo.component.scss']
})
export class NplogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
