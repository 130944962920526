<app-loader-animation *ngIf="isLoading"></app-loader-animation>
<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-light">
    <a (click)="bind_default()" class="navbar-brand pl-lg-4">
        <img [src]="bindlogo()" style="width:12rem;" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto">


            <!-- <li class="nav-item " (click)="DBclick()" *ngIf="comm.moduleids.split(',').indexOf('1') > -1">
                <a class="nav-link cur" [ngClass]="{'active': (this.comm.selectedlink === 31) }" routerLinkActive="active">Dashboard</a>
            </li> -->

            <!-- <li class="nav-item" (click)="bindPerformance()" *ngIf="comm.moduleids.split(',').indexOf('2') > -1">
						<a class="nav-link cur" [ngClass]="{'active': (comm.selectedlink == 3 )}"
							>Summary</a>
          </li>
           -->
            <li class="nav-item" (click)="gridclick()" *ngIf="comm.moduleids.split(',').indexOf('3') > -1">
                <a class="nav-link cur" [ngClass]="{'active': comm.selectedlink === 1 && comm.grididividual==1}">Summary</a>
            </li>

            <li class="nav-item" (click)="bindSummery_Performance()" *ngIf="comm.moduleids.split(',').indexOf('9') > -1">
                <a class="nav-link cur" [ngClass]="{'active': (comm.selectedlink === 10 )}">Performance</a>
            </li>
            <li class="nav-item" (click)="binddetails()" *ngIf="comm.moduleids.split(',').indexOf('10') > -1">
                <a class="nav-link cur" [ngClass]="{'active': (comm.selectedlink === 4 )}">Details</a>
            </li>
            <!-- <li class="nav-item" (click)="hotnotlink()">
                <a class="nav-link cur" [ngClass]="{'active': (comm.selectedlink === 51 )}" routerLinkActive="active">Hot/Not</a>
            </li> -->

            <!-- <li *ngIf="comm.moduleids.split(',').indexOf('9') > -1">
						<a style="cursor: pointer;" [ngClass]="{'active': (comm.selectedlink === 10 )}"
							(click)="bindSummery_Performance()" routerLinkActive="active">Performance</a>
          </li> -->


            <!-- <li *ngIf="comm.moduleids.split(',').indexOf('10') > -1">
						<a (click)="binddetails()" style="cursor: pointer;"
							[ngClass]="{'active': comm.selectedlink === 4}">Details</a>
          </li> -->

            <li class="nav-item" (click)="APclick()" *ngIf="comm.moduleids.split(',').indexOf('4') > -1">
                <a class="nav-link cur" style="font-size:11px !important;" [ngClass]="{'active': comm.selectedlink== 2}">
          Action Plans</a>
            </li>

            <li class="nav-item" (click)="insite_click()"  *ngIf="comm.moduleids.split(',').indexOf('7') > -1">
                <a class="nav-link cur"  [ngClass]="{'active': comm.selectedlink == 41}" routerLinkActive="active">insights</a>
            </li>


            <li class="nav-item" (click)="task()" *ngIf="comm.moduleids.split(',').indexOf('5') > -1">
                <a class="nav-link cur" [ngClass]="{'active':comm.selectedlink==8}" routerLinkActive="active">Tasks</a>
            </li>

            <li class="nav-item" (click)="Trendsclick()" *ngIf="comm.moduleids.split(',').indexOf('11') > -1">
                <a class="nav-link cur" [ngClass]="{'active': comm.selectedlink == 5}" routerLinkActive="active">Trends</a>
            </li>

            <!-- <li class="nav-item"*ngIf="comm.moduleids.split(',').indexOf('11') > -1">
          <a style="cursor: pointer;" (click)="Trendsclick()"
            [ngClass]="{'active': comm.selectedlink === 5}">Trends</a>
        </li> -->

            <!-- <li class="nav-item" (click)="gridclick()" *ngIf="comm.moduleids.split(',').indexOf('3') > -1">
						<a class="nav-link cur"
							[ngClass]="{'active': comm.selectedlink === 1 && comm.grididividual==1}">Grid</a>
					</li>
          <li class="nav-item" (click)="Groupclick()" *ngIf="comm.moduleids.split(',').indexOf('12') > -1">
              <a class="nav-link cur" [ngClass]="{'active': this.comm.selectedlink === 1  && comm.grididividual!=1}">Group</a>
            </li>
             -->
            <li class="nav-item" (click)="Adminclick()" *ngIf="comm.moduleids.split(',').indexOf('13') > -1">
                <a class="nav-link cur" [ngClass]="{'active': this.comm.selectedlink === 7 }" routerLinkActive="active">Admin</a>
            </li>
            <li class="nav-item" (click)="repdownarrow('1')" *ngIf="comm.moduleids.split(',').indexOf('14') > -1">
                <a class="nav-link cur" [ngClass]="{'active': this.comm.selectedlink === 61 }" routerLinkActive="active">Reports</a>
                <div class="up-arrow" *ngIf="downarrow">
                    <ul>
                        <li *ngIf="comm.moduleids.split(',').indexOf('30') > -1" (click)="openReports()" style="width: 100% !important;"><a style="border:none;cursor:pointer">AI Report</a></li>
                        <li *ngIf="comm.moduleids.split(',').indexOf('31') > -1" (click)="openUserstats()" style="width: 100% !important;"><a style="border:none;cursor:pointer">User Statistics</a></li>
                    </ul>
                </div>
            </li>

            <li class="nav-item" (click)="openMessenger()" *ngIf="comm.moduleids.split(',').indexOf('6') > -1">
                <a class="nav-link cur" style="padding-right: 0px !important;"><img src="assets/images/msg_sml.png"><a class="attach_icon" *ngIf="msgcnt>0">{{msgcnt}}</a></a>

            </li>

        </ul>
        <div class="row" style="margin-left: 0 !important;">
            <div class="pic" style="border:none;outline:none;cursor: pointer;">
                <img class="profile_pic cur" (click)="usersettings()" src="{{comm.proimg}}" style="cursor: pointer;" onError="this.src='../../../assets/images/default-img.jpg'">
            </div>
            <div class="profile_det cur" routerLink="/3" style="border:none;outline:none;">
                <div class="prof_name">{{userInfo.firstname != null?userInfo.firstname :'' }} {{userInfo.lastname != null?userInfo.lastname :''}}</div>
                <div class="prof_role">{{userInfo.rolename != null?userInfo.rolename :'--' }}</div>
            </div>
            <div class="mr-2 mt-2">
                <a href="javascript:void(0);" (click)="logOut()"><img src="../../assets/images/logout_icon.png" style="height: 25px;"></a>
            </div>
        </div>
    </div>
</nav>