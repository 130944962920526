import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs';
@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

  constructor() { }

  confirm(message: string, title: string, okCallback: () => any) {
    alertify.confirm(message, (e) => {
      if (e) {
        okCallback();
        return true;
      } else {
      }
    }).set({ title: title }).set({ labels: { ok: 'Ok', cancel: 'Cancel' } });
  }

  success(message: string) {
    alertify.success(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  error(message: string) {
    alertify.error(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  warning(message: string) {
    alertify.warning(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  message(message: string) {
    alertify.message(message);
    alertify.set('notifier', 'position', 'top-center');
  }

  n_errorcount=0
  v_message:any=''

 addErrorMessage(msg) {
    console.log(msg);
 
    this.n_errorcount = this.n_errorcount + 1;
    this.v_message =
      this.v_message + "(" + this.n_errorcount + ") " + msg + "<br/>";
    console.log(' this.v_message', this.v_message);
    
  }
  displayErrors() {
    if (this.n_errorcount == 0) {
      return true;
    } else {
      console.log(' this_message', this.v_message);
      
      // alertify.confirm({
      //   title: 'Warning!',
      //   html: "Please check the following:" + '<br/><br/ >' + this.v_message,
      //   icon: 'info',
      //   confirmButtonText: 'OK',
      //   imageWidth: 50,
      //   imageHeight: 50,
      //   iconColor: "red"
 
      // })
      // let alert = this.alertController.create();
      // alert.setTitle("Please check the following: <br/><br/>" + this.v_message);
      // alert.addButton({
      //   text: "OK"
      // });
      // alert.present();
       alertify.error(this.v_message);
       alertify.set('notifier', 'position', 'top-center');

      this.v_message = "";
      this.n_errorcount = 0;
      return false;
    }
  }

}
