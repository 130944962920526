import { LimitCharsPipe } from './limit-chars';
//LimitCharsPipeModule
import { NgModule } from '@angular/core';






@NgModule({
	declarations: [LimitCharsPipe],
	exports: [LimitCharsPipe],
	providers:[]
})
export class LimitCharsPipeModule {}