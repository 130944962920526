import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ActonPlanFollowersComponent } from './acton-plan-followers.component';
import { NgbActiveModal, NgbModalModule, } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [ActonPlanFollowersComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgbModalModule
  ],
  exports: [ActonPlanFollowersComponent],
  entryComponents: [ActonPlanFollowersComponent],
  providers: [NgbActiveModal]
})
export class ActonPlanFollowersModule { }
