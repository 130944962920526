import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from 'src/app/Providers/alertify/alertify.service';
import { NPApi } from 'src/app/Providers/NP-Api';
import * as moment from 'moment';
declare var require: any;
@Component({
  selector: 'app-action-plan-tak',
  templateUrl: './action-plan-tak.component.html',
  styleUrls: ['./action-plan-tak.component.scss']
})
export class ActionPlanTakComponent implements OnInit {
  @Output() onCloseTask = new EventEmitter();
  @Output() onAddUpdateTask = new EventEmitter();
  @Input() actionPlanTitle: any;
  @Input() actionPlanId: any;
  @Input() actionPlanTargetDate: any;
  @Input() actionPlanTaskList: any;
  userInfo: any;
  type = "A";
  assignee: any;

  priorityValue = '1';
  tasksList: any[] = [];
  dueDate: any;
  taskForm: FormGroup;
  assigneeType = "";
  actionPlantype: string = 'A';
  taskid = 0;

  datePickerConfig: any;
  constructor(private modalService: NgbModal, private ngbActiveModal: NgbActiveModal, private npSrv: NPApi, private alertfy: AlertifyService,
    private fb: FormBuilder,) {
    this.datePickerConfig = {
      //...this.datePickerConfig,
      format: 'MM/DD/YY',
      openOnFocus: false
    }
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    this.dueDate = new Date().toISOString().substring(0, 10);;
  //console.log(this.dueDate, 'nag');

    if (this.actionPlanId != 0) {
      this.getActonPlanTask()
    } else {
      this.tasksList = this.actionPlanTaskList;
    }
    this.taskForm = this.fb.group({
      taskTitleAction: [''],
      priority: ['1'],
      dueDate: ['']
    });

  }

  closeTask() {

    this.onCloseTask.emit(this.tasksList);
  }
  assignedItem: any;
  taskAssignee: any;

  openActionPlanFollowers(content, type, item, assigneType) {
    type == "A" ? this.type = 'A' : this.type = 'F';
    this.assignedItem = item;
    //this.assignee = this.assignee;
  //console.log(this.assignee);
    this.assigneeType = assigneType;
    if (this.assignedItem != undefined && this.assignedItem != "") {
      let assigned: any = this.convertAssignerXmlEdit(this.assignedItem.Assignedby);
      this.taskAssignee = assigned;
    }

    this.ngbActiveModal = this.modalService.open(content, { size: 'lg', });
  }
  closeModalf(data) {
  //console.log(data);

    this.isLoading = false;
    this.ngbActiveModal.close();
    if (this.assignedItem != undefined && this.assignedItem != "") {
      this.assignee = undefined;
    }

  }
  changeImg(data) {
    //this.assignee = data.assignee;

  //console.log(data, 'vv');

    if (this.assignedItem != undefined && this.assignedItem != "") {
      this.assignee = undefined;
      let objParam = {
        type: 'A',
        APTicket_Id: this.assignedItem.ta_Ticket,
        APT_Priority: this.taskForm.value.priority,
        APT_DueDate: this.assignedItem.ta_DueDate,
        APT_AssignedId: data.assignee.sc_id,
        APT_UId: this.userInfo.id
      }
      this.npSrv.getEditTask(objParam).subscribe((restask: any) => {
        if (restask.status == 200) {
          this.alertfy.success(restask.response);
          this.getActonPlanTask();
        } else {
          this.alertfy.warning(restask.error);
        }
      }, err => {

      })

    } else {
      this.type == 'A' ? this.assignee = data.assignee : this.assignee;
    //console.log(this.assignee, 'vv');
      if (this.assignee != undefined) {
        if (this.assignee.length == 0) {
          this.assignee = undefined;
        } else {
          let assigned = this.assignee != undefined ? this.assignee.sc_id : 0;
          //let assigned: any = this.convertAssignerXmlEdit(item.Assignedby)
        }
      }
    }


    this.ngbActiveModal.close();
  }
  onOptionsSelected(ev) {
    const value = ev.target.value;
    this.priorityValue = value;
  }
  onOptionsSelectedUpdate(ev, item) {
    item.ta_Priority = ev.target.value;
  //console.log(item, 'vv');

    let assigned: any = this.convertAssignerXmlEdit(item.Assignedby)
    let assigne = assigned != undefined ? parseInt(assigned.sc_id) : 0;
    let objParam = {
      type: 'P',
      APTicket_Id: item.ta_Ticket,
      APT_Priority: item.ta_Priority,
      APT_DueDate: this.taskForm.value.dueDate,
      APT_AssignedId: assigne,
      APT_UId: this.userInfo.id
    }
    this.npSrv.getEditTask(objParam).subscribe((restask: any) => {
      if (restask.status == 200) {
        this.alertfy.success(restask.response);
        this.getActonPlanTask();
      } else {
        this.alertfy.warning(restask.error);
      }
    }, err => {

    })

  }

  onSubmit() {
    //console.log(this.taskForm.value);

    //this.dueDate = new Date().toISOString().substring(0, 10);;
    let userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    let assigned = this.assignee != undefined ? this.assignee.sc_id : 0;
    if (this.actionPlanId != 0) {
      if (this.taskForm.value.taskTitleAction.length != 0) {

        let obj = {
          ta_Id: "0",
          ta_Ticket: this.userInfo.id,
          ta_U_UId: userInfo.id,
          ta_TS_Id: 1,
          ta_acp_id: this.actionPlanId,
          ta_TaskName: this.taskForm.value.taskTitleAction,
          ta_TaskDetails: "",
          ta_Status: "Y",
          ta_DealerId: localStorage.getItem('store_id'),
          ta_Priority: this.taskForm.value.priority,
          ta_TaskType: "A",
          ta_Loginfrom: "D",
          ta_ParentId: 0,
          AST_FollowesIds: "0",
          Assignto_uid: assigned,
          ta_DueDate: this.taskForm.value.dueDate,
          action: "A"
        }
      //console.log(obj, 'mo');
        this.npSrv.addAndUpdateTaskAssign(obj).subscribe((resTask: any) => {
          if (resTask.status == 200) {

            this.assignee = undefined;
            this.taskForm.setValue({
              taskTitleAction: '',
              priority: '1',
              dueDate: new Date().toISOString().substring(0, 10)
            })
            this.getActonPlanTask();
            this.priorityValue = '1';
            //this.alertfy.success(resTask.response)

            this.onAddUpdateTask.emit(resTask)
          }
        }, err => {
        //console.log(err);

        })
      } else {
        this.alertfy.warning("Please Enter Task Name!")
      }
    } else {
      let data: any;

      data = this.assignee;
    //console.log(data);

      const result = `<Assignee><Assign><sc_id>${data.sc_id}</sc_id><sc_firstname>${data.sc_firstname}</sc_firstname><sc_lastname>${data.sc_lastname}</sc_lastname><sc_profile_image>${data.sc_profile_image}</sc_profile_image><sc_title>${data.sc_title}</sc_title></Assign></Assignee>`;
    //console.log(result)
      let obj = {
        ta_Id: "0",
        ta_Ticket: "1",
        ta_U_UId: userInfo.id,
        ta_TS_Id: 1,
        ta_acp_id: this.actionPlanId,
        ta_TaskName: this.taskForm.value.taskTitleAction,
        ta_TaskDetails: "",
        ta_Status: "Y",
        ta_DealerId: localStorage.getItem('store_id'),
        ta_Priority: this.taskForm.value.priority,
        ta_TaskType: "A",
        ta_Loginfrom: "D",
        ta_ParentId: 0,
        AST_FollowesIds: "0",
        Assignto_uid: assigned,
        ta_DueDate: this.taskForm.value.dueDate,
        Assignedby: result,
        action: "A"
      }
      this.tasksList.push(obj)
      this.assignee = undefined;
      this.taskForm.setValue({
        taskTitleAction: '',
        priority: '1',
        dueDate: new Date().toISOString().substring(0, 10)
      })
      this.priorityValue = "1";
      //console.log(this.tasksList, 'vv');

      //this.onAddUpdateTask.emit(obj)
    }


  }

  getActonPlanTask() {
    // this.tasksList = null;
    let obj = {
      ta_acp_id: this.actionPlanId,
      expression: ""
    }
    this.npSrv.getActionPlansTask(obj).subscribe((resTask: any) => {
      if (resTask.status == 200) {
        this.tasksList = resTask.response;
      //console.log(this.tasksList, 'mm');
        this.tasksList = this.tasksList.map(el => {
          let obj = {
            Assignedby: el.Assignedby,
            Followers: el.Followers,
            ta_AckByTs: el.ta_AckByTs,
            ta_AckBy_U_UId: el.ta_AckBy_U_UId,
            ta_AssignTS: el.ta_AssignTS,
            ta_Assignby_U_UId: el.ta_Assignby_U_UId,
            ta_Closedts: el.ta_Closedts,
            ta_DealerId: el.ta_DealerId,
            ta_Id: el.ta_Id,
            ta_Loginfrom: el.ta_Loginfrom,
            ta_ParentId: el.ta_ParentId,
            ta_Priority: el.ta_Priority,
            ta_Status: el.ta_Status,
            ta_TS_Id: el.ta_TS_Id,
            ta_TaskDetails: el.ta_TaskDetails,
            ta_TaskName: el.ta_TaskName,
            ta_TaskType: el.ta_TaskType,
            ta_Ticket: el.ta_Ticket,
            ta_Ts: el.ta_Ts,
            ta_UTs: el.ta_UTs,
            ta_U_UId: el.ta_U_UId,
            ta_UserName: el.ta_UserName,
            ta_acp_id: el.ta_acp_id,
            ta_duedate: el.ta_duedate,
            ta_DueDate: this.convertDate(el.ta_DueDate),
          }
          return obj;
        })

      }
    }, err => {
      this.alertfy.error("Some Net Issue Please try after some time!");
    })
  }
  convertAssignerXml(stores) {
    //console.log(stores);

    let storeObj: any[] = [];
    if (stores != null || stores != "") {
      // let s = "<stores>" + stores + "</stores>";
      let res: any[] = [];
      let parseString = require('xml2js').parseString;

      parseString(stores, function (err, result) {
        if (result != undefined) {
          if (result.Assignee != undefined) {
            res = result.Assignee.Assign
          }
        }
      });

      if (res) {
        for (var i = 0; i < res.length; i++) {
          storeObj.push({
            'sc_id': res[i].sc_id, 'sc_firstname': res[i].sc_firstname, 'sc_lastname': res[i].sc_lastname, 'sc_stores': res[i].sc_stores,
            'sc_title': res[i].sc_title, 'sc_profile_image': res[i].sc_profile_image, 'sc_status': res[i].sc_status
          })
        }
        //console.log(storeObj, 'hh');

        return storeObj;
      }
    }
  }


  changeDueDate(dueDateSelected) {
    this.dueDate = dueDateSelected;
    //console.log(this.dueDate, 'jj');

  }

  convertDate(date) {
    if (date != null) {
      //let myDate = moment(date).format('YYYY-MM-DD');
      //console.log(myDate);
      let myDate = moment(date).format('MM/DD/YY');
      return myDate
    }

  }

  onOptionsDateUpdate(ev, item) {
    item.ta_DueDate = ev.target.value;
    let assigned: any = this.convertAssignerXmlEdit(item.Assignedby);
    let assignee = assigned != undefined ? parseInt(assigned.sc_id) : 0;
    let objParam = {
      type: 'D',
      APTicket_Id: item.ta_Ticket,
      APT_Priority: this.taskForm.value.priority,
      APT_DueDate: item.ta_DueDate,
      APT_AssignedId: assignee,
      APT_UId: this.userInfo.id
    }
    this.npSrv.getEditTask(objParam).subscribe((restask: any) => {
      if (restask.status == 200) {
        this.alertfy.success(restask.response);
        this.getActonPlanTask();
      } else {
        this.alertfy.warning(restask.error);
      }
    }, err => {
      this.alertfy.error("Some Net Issue Please try after some time!");
    })
  }
  convertAssignerXmlEdit(stores) {
    //console.log(stores);

    let storeObj = [];
    if (stores != null || stores != "") {
      // let s = "<stores>" + stores + "</stores>";
      let res: any = [];
      let parseString = require('xml2js').parseString;

      parseString(stores, function (err, result) {
      //console.log(result);
        if (result != undefined) {
          if (result.Assignee != undefined) {
            res = result.Assignee.Assign;
          }

        }
      });

      if (res) {


        storeObj = res[0]
        //}
        //console.log(storeObj);

        return storeObj;
      }
    }
  }

  isLoading = false;
  edittaskArray: any;

  edittask(id, content, temp, type, alertContent) {
    // this.actionPlantype = flag;
    // this.alertContent = content;
    this.isLoading = true;
    this.taskid = id;
    // let obj = {
    //   "Id": this.taskid,
    //   "expression": ""
    // }


    // //this.alertContent = alertContent;
    // console.log(obj);

    // this.isLoading = true;
    // this.npSrv.gettaskbyid(obj).subscribe((res: any) => {

    //   this.isLoading = false;
    //   if (res.status == 200) {
    //     this.edittaskArray = res.response.Tasks.data;
    //   //console.log(this.edittaskArray);

    //   }
    // }, err => {
    //   this.isLoading = false;
    // })
    //  this.onCloseAlert('E', content)
    //this.ngbmodalActive = this.ng.open(temp, { size: 'sm', backdrop: 'static', windowClass: 'Center-aligned' });
    setTimeout(() => {
      this.ngbActiveModal = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    }, 1500);

    this.taskid = id;
    // this.alertContent = alertContent;
    // console.log(this.actionPlanArray);
    this.actionPlantype = type;
  //console.log(this.taskid);

    //this.onCloseAlert('E', content)
  }

  changeOutput(data) {
    console.log(data, 'vv');
    if (data.taskArray != undefined) {
      this.tasksList.push(data.taskArray)
      this.tasksList = this.tasksList.map(el => {
        let obj = {
          Assignedby: el.Assignedby,
          Followers: el.AST_FollowesIds,
          ta_AckByTs: el.ta_AckByTs,
          ta_AckBy_U_UId: el.ta_AckBy_U_UId,
          ta_AssignTS: el.ta_AssignTS,
          ta_Assignby_U_UId: el.Assignto_uid,
          ta_Closedts: el.ta_Closedts,
          ta_DealerId: el.ta_DealerId,
          ta_Id: el.ta_Id,
          ta_Loginfrom: el.ta_Loginfrom,
          ta_ParentId: el.ta_ParentId,
          ta_Priority: el.ta_Priority,
          ta_Status: el.ta_Status,
          ta_TS_Id: el.ta_TS_Id,
          ta_TaskDetails: el.ta_TaskDetails,
          ta_TaskName: el.ta_TaskName,
          ta_TaskType: el.ta_TaskType,
          ta_Ticket: el.ta_Ticket,
          ta_Ts: el.ta_Ts,
          ta_UTs: el.ta_UTs,
          ta_U_UId: el.ta_U_UId,
          ta_UserName: el.ta_UserName,
          ta_acp_id: el.ta_acp_id,
          ta_duedate: el.ta_duedate,
          ta_DueDate: this.convertDate(el.ta_DueDate),
        }
        return obj;
      })
    }
    if (this.actionPlanId != 0) {
      this.getActonPlanTask()
    }
  }

  openActionPlanTask(content) {
    this.actionPlantype = "A";
    this.ngbActiveModal = this.modalService.open(content, { size: 'lg', backdrop: 'static' });
  }

}
