import { Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from 'src/app/Providers/alertify/alertify.service';
import { NPApi } from 'src/app/Providers/NP-Api';
import { FilterItemsPipe } from '../../pipes/filter-items/filter-items.pipe'

@Component({
  selector: 'app-acton-plan-followers',
  templateUrl: './acton-plan-followers.component.html',
  styleUrls: ['./acton-plan-followers.component.scss']
})
export class ActonPlanFollowersComponent implements OnInit {
  followersArray: any;
  followersArrayCopy: any;
  @Input() followersassigned: any;
  @Input() assignee: any;
  @Input() approvedBy: any;
  @Input() type: any;
  @Input() apCreatedId: any;
  @Input() taskAssignee: any;
  id: any;
  modalShow = true;
  @Output() onClose = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  userInfo: any;


  constructor(private npSrv: NPApi, private ngbModal: NgbModal,
    private ngbModl: NgbActiveModal, private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    this.assignee = this.taskAssignee != undefined ? this.taskAssignee : this.assignee;
    this.followers = this.followersassigned != undefined ? this.followersassigned : this.followers;

    setTimeout(() => {
      this.getFollowersByStrore();
    }, 500);

    //this.followers = this.followersassigned;
    if (this.type == "F") {
      if (this.followersassigned.length != 0) {
        for (let i = 0; i < this.followersassigned.length; i++) {
          this.followesIds.push(this.followersassigned[i].sc_id)
        }
      }
    }
    ///console.log(this.type);








  }
  //   ngOnChanges(changes: SimpleChanges) {
  //     if (changes['followersassigned'].currentValue != undefined) {
  //       let variableChange = changes['followersassigned'].currentValue;
  //       console.log(variableChange, this.type);
  // 
  //       //do something with variableChange.currentValue
  //     }
  //   }
  close() {
    //console.log(this.ngbModl);
    //this.modalRef.hide();
    //this.modalShow = false;
    //this.ngbModl.close();


    this.onClose.emit("close")
  }

  getFollowersByStrore() {
    console.log(this.apCreatedId, 'mmm');

    let storeValue = localStorage.getItem("store_id");
    //let storeValue = "0014A00002KEUwBQAX";
    let obj = { storeID: storeValue }
    this.npSrv.getUsersData(obj).subscribe((resFollowers: any) => {
      // const filterPipe = new FilterItemsPipe();
      // const fiteredArr = filterPipe.transform(JSON.parse(resFollowers), localStorage.getItem("store_id"));
      const fiteredArr = resFollowers.response;
      console.log(fiteredArr);
      if (fiteredArr.length > 0) {
        this.followersArray = fiteredArr;
        this.followersArray = this.followersArray.map(el => {
          let obj = {
            sc_id: el.sc_id,
            sc_firstname: el.sc_firstname,
            sc_lastname: el.sc_lastname,
            sc_title: el.sc_title,
            sc_profile_image: el.sc_profile_image,
            sc_stores: el.sc_stores,
            sc_roleName: el.RoleName,
            sc_city: el.sc_city,
            sc_status: this.getstatus(el)
          }
          return obj;
        }, err => {
          this.alertify.error("Some Net Issue Please try after some time!");
        })

        console.log(this.followersArray);
        if (this.type == "F") {
          if (this.userInfo != undefined) {
            this.followersArray = this.followersArray.filter(item => {
              return item.sc_id !== this.userInfo.id;
            })
          }
        }
        if (this.apCreatedId != undefined && this.type != "F") {
          this.followersArray = this.followersArray.filter(item => {
            return item.sc_id 
            //!== this.apCreatedId;
          })
        }

        //         if (this.type != 'A') {
        //           if (this.assignee != undefined || this.assignee != null) {
        //             this.followersArray = this.followersArray.filter(B => {
        //               return B.sc_id !== parseInt(this.assignee.sc_id)
        //             })
        //           }
        //         }
        //         if (this.type != "AP") {
        //           if (this.approvedBy != undefined || this.approvedBy != null) {
        //             this.followersArray = this.followersArray.filter(B => {
        //               return B.sc_id !== parseInt(this.approvedBy.sc_id)
        //             })
        //           }
        //         }
        // 
        // 

      }
      this.followersArrayCopy = this.followersArray;
    })
  }

  getstatus(userObj) {
    let st: Boolean = false;
    console.log(this.assignee);

    if (this.type == "F") {

      if (typeof this.followersassigned !== 'undefined') {
        this.followersassigned.forEach(element => {
          if (element.sc_id == userObj.sc_id)
            st = true;
        });
      }
      return st;
    } else if (this.type == 'A') {
      if (typeof this.assignee !== 'undefined') {
        // this.assignee.forEach(element => {
        if (this.assignee.sc_id == userObj.sc_id)
          st = true;
        //});
      }
      return st;
    } else {
      if (typeof this.approvedBy !== 'undefined') {
        // this.assignee.forEach(element => {
        if (this.approvedBy.sc_id == userObj.sc_id)
          st = true;
        //});
      }
      return st;
    }
  }
  followesIds = [];
  followers = [];
  unFollowers = [];
  assignner = [];
  approveBy = []
  checkFollower(item, ev) {
    let st = false;
    if (ev.target.checked) {
      this.unFollowers = []
      if (this.type === 'F') {
        this.followesIds.push(item.sc_id);
        this.followers.push(item);
        //console.log(this.followers, 'monika');
      }
      this.type == 'A' ? this.assignner = item : '';
      this.type == 'AP' ? this.approveBy = item : '';
      if (this.type == 'A' || this.type == 'AP') {
        if (typeof this.followersArray !== 'undefined') {
          this.followersArray.forEach(element => {
            if (element.sc_id == item.sc_id) {
              element.sc_status = true;
            } else {
              return element.sc_status = false;
            }
          });
        }
      }
      let ids = this.followers.map(o => o.sc_id)
      this.followers = this.followers.filter(({ sc_id }, index) => !ids.includes(sc_id, index + 1));
      //console.log(this.followers, 'vv');


    } else {
      //this.followesIds.splice(this.followesIds.indexOf(item.sc_id), 1);
      //if (this.type != 'F') { this.followers.splice(this.followers.indexOf(item), 1); }
      console.log(this.followers, 'hh');

      if (this.type != 'A') {
        // this.followersassigned.splice(this.followersassigned.indexOf(item), 1)
      }
      this.unFollowers.push({ ...item, })
      this.type == 'A' ? this.assignner = [] : '';
      this.type == 'A' ? this.assignee = [] : '';
      this.type == 'AP' ? this.approvedBy = [] : '';
      this.type == 'AP' ? this.approveBy = [] : '';
      let ids = this.unFollowers.map(o => o.sc_id)
      this.unFollowers = this.unFollowers.filter(({ sc_id }, index) => !ids.includes(sc_id, index + 1))

    }
    this.followers
  }

  saveFollowers() {
    //console.log(this.followesIds;
    let param: any = '';
    if (this.type == 'F') {
      console.log(this.followers)
      param = { followerIds: this.followesIds, followers: this.followers, unFollowers: this.unFollowers, };
    } else if (this.type == "A") {
      this.followersassigned != undefined ? this.followesIds.push(this.followersassigned.sc_id) : []

      this.followesIds = this.followesIds;
      this.unFollowers = this.unFollowers;
      this.assignner = this.assignner.length != 0 ? this.assignner : this.assignee;
      //console.log(this.assignner, 'monika');

      param = { assignee: this.assignner, followerIds: this.followesIds, followers: this.followersassigned, unFollowers: this.unFollowers }
    } else {
      this.followersassigned != undefined ? this.followesIds.push(this.followersassigned.sc_id) : []

      this.followesIds = this.followesIds;
      this.unFollowers = this.unFollowers;
      this.assignner = this.assignner.length != 0 ? this.assignner : this.assignee;
      this.approveBy = this.approveBy.length != 0 ? this.approveBy : this.approvedBy
      param = { assignee: this.assignner, followerIds: this.followesIds, followers: this.followersassigned, unFollowers: this.unFollowers, approveBy: this.approveBy }
    }
    //this.modalShow = false;
    //this.ngbModal.dismissAll();
    this.onAdd.emit(param);
    console.log(param, 'bb');

    // this.modalSrv.setDismissReason(param)
    // this.modalRef.hide();

  }


  searchFilter(ev) {
    //let searchItem;
    //console.log(ev.target.value);
    let searchValue = ev.target.value;
    if (searchValue == '') {
      console.log(searchValue, 'll');

      this.followersArray = this.followersArrayCopy;
    } else {
      this.followersArray = this.followersArrayCopy.filter(item => {
        if (searchValue == '') {
          console.log(searchValue, 'll');

          this.followersArray = this.followersArrayCopy;
        } else {
          if (item.sc_firstname != null && item.sc_firstname != "") {
            return item.sc_firstname.toLowerCase().includes(searchValue.trim().toLowerCase())
          } else if (item.sc_lastname != null && item.sc_lastname != "") {
            return item.sc_lastname.toLowerCase().includes(searchValue.trim().toLowerCase())
          } else if (item.sc_roleName != null && item.sc_roleName != "") {
            return item.sc_roleName.toLowerCase().includes(searchValue.trim().toLowerCase())
          } else {
            return item.sc_city.toLowerCase().includes(searchValue.trim().toLowerCase())
          }



        }
        //console.log(searchItem);

      })
    }

  }
}
