<div (click)="this.openBlockDiv = !this.openBlockDiv;OpenBlock()" class="row selectParent">
    <!-- <div class="col-2 text-right pr-xl-0 mb-xl-1 pt-1 Imgclass" [style.backgroundImage]="'url('+apiconn1 +this.selectedImg+')'" style=" background-size: 75% 75%;"></div> -->
    <label style="margin:auto;" class="col-9 pl-xl-1 selectParentLabel" *ngIf="this.comm.selectedStore?.length != 0">{{selectedText}} </label>
    <label style="margin:auto;" class="col-9 pl-xl-1 selectParentLabel" *ngIf="this.comm.selectedStore?.length == 0">Automotive Group</label>
</div>
  <div class="selectDiv" style="z-index: 9999999;" *ngIf="openBlockDiv" [ngClass]="{hide: !openBlockDiv}">
    <input class="search" type="text" [(ngModel)]="searchInput" (keyup)="fetchSeries($event)" />
    <li *ngFor="let option of CopiedOptions"  (click)="this.openBlockDiv = !this.openBlockDiv;" class="row HeaderParentli" (click)="ddlchange(option)" [ngStyle]="selectedText == option[optionText] && {'background-color': 'rgb(94 165 216)'}">
      <div class="col-2 text-right pr-xl-0 pt-1 Imgclass" [style.backgroundImage]="'url('+apiconn1 +option[optionImg]+')'"></div>
      <a class="col-9 pl-xl-1" style="color:black;font-size: 0.9rem;padding: 0.2rem 0.6rem;margin-top: 0.2rem;"  [ngStyle]="selectedText == option[optionText] && {'color':'#ffffff !impotant'}">
        <label> {{option[optionText]}} </label>
      </a>
    </li>
  </div>
  <div style="height: 1000%;width: 1000%;position: fixed;left: -100%;top: -100%;bottom: -100%;z-index: 99999;"  (click)="this.openBlockDiv = !this.openBlockDiv;" [ngClass]="{hide: !openBlockDiv}"></div>