import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
//import { HeaderComponent } from './header.component';
// import { NplogoModule } from '../NpLogos/nplogo/nplogo.module';
//import { InlineSVGModule } from 'ng-inline-svg';
// import { HttpClientModule } from '@angular/common/http';
// import { InlineSVGModule } from 'ng-inline-svg';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,


  ],
  exports: [HeaderComponent],
  entryComponents: [HeaderComponent],
})
export class HeaderComponentModule { }
