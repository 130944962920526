<ul class="nav flex-column flex-nowrap overflow-hidden">
    <li #1 (click)="getsection('1')" class="nav-item">
        <a [ngClass]="(comm.selectedItem=='1')?'nav-link active':'nav-link'"><img src="assets/images/menuicons/Cash.png">
            <span class="d-none d-md-none d-sm-none d-lg-inline">Cash</span> <span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img
					src="assets/images/menuicons/Arrow.png"></span> <span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right; margin-right: 20px;margin-top:0;"><img [ngClass]="{'cauion_clss':'C'==comm.gra_cash,'iocn_cls':comm.gra_cash != 'C'}"  [src]="bind_grade(comm.gra_cash)"></span>            </a>
    </li>
    <li #2 (click)="getsection('6')" class="nav-item">
        <a [ngClass]="(comm.selectedItem=='6')?'nav-link active':'nav-link'"><img src="assets/images/menuicons/Gross.png">
            <span class="d-none d-md-none d-sm-none d-lg-inline">Gross</span> <span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img
					src="assets/images/menuicons/Arrow.png"></span> <span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;margin-right: 20px;margin-top:0;"><img [ngClass]="{'cauion_clss':'C'==comm.gra_revenue,'iocn_cls':comm.gra_revenue != 'C'}" [src]="bind_grade(comm.gra_revenue)"></span></a>
    </li>
    <li #3 (click)="getsection('3')" class="nav-item">
        <a [ngClass]="(comm.selectedItem=='3')?'nav-link active':'nav-link'"><img src="assets/images/menuicons/Expenses.png"> <span class="d-none d-md-none d-sm-none d-lg-inline">Expense</span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img
					src="assets/images/menuicons/Arrow.png"></span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;margin-right: 20px;margin-top:0;"><img [ngClass]="{'cauion_clss':'C'==comm.gra_expense,'iocn_cls':comm.gra_expense != 'C'}" [src]="bind_grade(comm.gra_expense)"></span></a>
    </li>
    <li #4 (click)="getsection('4')" class="nav-item">
        <a [ngClass]="(comm.selectedItem=='4')?'nav-link active':'nav-link'"><img src="assets/images/menuicons/Comp.png">
            <span class="d-none d-md-none d-sm-none d-lg-inline">Comp</span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img
					src="assets/images/menuicons/Arrow.png"></span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;margin-right: 20px;margin-top:0;"><img [ngClass]="{'cauion_clss':'C'==comm.gra_comp,'iocn_cls':comm.gra_comp != 'C'}" [src]="bind_grade(comm.gra_comp)" ></span></a>
    </li>
    <li #5 (click)="getsection('5')" class="nav-item">
        <a [ngClass]="(comm.selectedItem=='5')?'nav-link active':'nav-link'"><img src="assets/images/menuicons/Profit.png">
            <span class="d-none d-md-none d-sm-none d-lg-inline">Profit</span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img
					src="assets/images/menuicons/Arrow.png"></span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;margin-right: 20px;margin-top:0;"><img [ngClass]="{'cauion_clss':'C'==comm.gra_profit,'iocn_cls':comm.gra_profit != 'C'}" [src]="bind_grade(comm.gra_profit)"></span></a>
    </li>
    <!-- <li #6 (click)="getsection('6')"  *ngIf="revenue?.section?.length>0"   class="nav-item "><a [ngClass]="(comm.selectedItem=='6')?'nav-link active':'nav-link'" ><img src="assets/images/menuicons/Cash.png"> <span class="d-none d-md-none d-sm-none d-lg-inline">Revenue</span> <span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img src="assets/images/menuicons/Arrow.png"></span> <span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right; margin-right: 20px;"><img src="assets/images/menuicons/Caution.png"></span> </a></li> -->

    <li #7 (click)="getsection('7')">
        <a [ngClass]="(comm.selectedItem=='7')?'nav-link active':'nav-link'"><img src="assets/images/menuicons/inv.png">
            <span class="d-none d-md-none d-sm-none d-lg-inline">Inventory</span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;"><img
					src="assets/images/menuicons/Arrow.png"></span><span class="d-none d-md-none d-sm-none d-lg-inline" style="float: right;margin-right: 20px;margin-top:0;"><img [ngClass]="{'cauion_clss':'C'==comm.gra_comp,'iocn_cls':comm.gra_comp != 'C'}" ></span></a>
    </li>
    <span></span>

</ul>



<div class="header">
    <label style="margin:.2rem;font-family:'Gotham Bold' !important">Notifications</label>
</div>
<div>
    <div style="overflow-y: auto ;overflow-x: hidden;" class="col-lg-12 msngr-pnl">
        <div class="row " *ngFor="let item of comm.notifyslist,let i = index">
            <div class="col-12 chat-bx" *ngIf="item.ntype == 'M'">

                <div class="content" *ngFor="let data of parseXml(item.notifications)" (click)="navigatetodetails(item,data,templateTask,template)">
                    From: {{data.Messages.Message[0].MsgFrom[0]}}
                    <h5>{{data.Messages.Message[0].msgTitle[0]}}</h5>
                    <span style="float: right;color:#0090f5;">{{item.createddate | date: 'MM/dd/yyyy h:mm a'}}</span>
                </div>
                <div class="header">
                    <!-- <div>Messenger</div>
                                    <div class="sub-title"></div> -->
                </div>
            </div>

            <div class="col-12 chat-bx" *ngIf="item.ntype == 'A'">

                <div class="content" *ngFor="let data of parseXml(item.notifications)" (click)="navigatetodetails(item,data,templateTask,template)">
                    <h5>Action Plan: {{data.Actionplans.Actionplan[0].Aptitle[0]}}</h5>
                    <p *ngIf="data.Actionplans.Actionplan[0].AssignedTo[0].Assignee[0] != ''">
                        <label *ngIf="data.Actionplans.Actionplan[0].AssignedTo[0].Assignee[0].Assign[0].sc_id != userInfo.id">Assigned To: {{data.Actionplans.Actionplan[0].AssignedTo[0].Assignee[0].Assign[0].sc_firstname[0]}}  {{data.Actionplans.Actionplan[0].AssignedTo[0].Assignee[0].Assign[0].sc_lastname[0]}}</label>
                        <label *ngIf="data.Actionplans.Actionplan[0].AssignedTo[0].Assignee[0].Assign[0].sc_id == userInfo.id">Assigned to You</label>
                        <br><span style="float: right;color:#0090f5;">{{item.createddate | date: 'MM/dd/yyyy h:mm a'}}</span>
                    </p>
                    <!-- <div class="">
                                    <img class="prf_pic"
                                        *ngIf="itemAssigned?.sc_profile_image!=''"
                                        src="{{this.apiconn}}{{data.Actionplans.Actionplan[0].AssignedTo[0].Assignee[0].Assign[0]?.sc_profile_image[0]}}"
                                        onError="this.src='../../../assets/images/default-img.jpg'">
                                    <img class="prf_pic"
                                        *ngIf="itemAssigned?.sc_profile_image==''"
                                        src="../../../assets/images/default-img.jpg">
                                </div> -->
                </div>
                <div class="header">
                    <!-- <div>Action Plans</div> -->
                    <!-- <div class="sub-title">{{item.createddate | date :'shortTime'}}</div> -->
                </div>
            </div>

            <div class="col-12 chat-bx" *ngIf="item.ntype == 'T'">



                <div class="content" *ngFor="let data of parseXml(item.notifications)" (click)="navigatetodetails(item,data,templateTask,template)">

                    <h5>Task: {{data.Tasks.Task[0].Taskname[0]}}</h5>

                    <!-- <h4>{{data.Tasks.Task[0].Taskdetails[0]}}</h4> -->

                    <p>
                        <label *ngIf="data.Tasks.Task[0].AssignedId != userInfo.id">Assigned To: {{data.Tasks.Task[0].AssignedTo[0]}}</label>
                        <label *ngIf="data.Tasks.Task[0].AssignedId == userInfo.id">Assigned to You</label>
                        <br>
                        <span style="float: right;color:#0090f5;">{{item.createddate | date: 'MM/dd/yyyy h:mm a'}}</span>
                    </p>

                </div>
                <div class="header">
                    <!--     
                                                                            <div>Task</div>
                                            
                                                                            <div class="sub-title">{{item.createddate | date :'shortTime'}}</div> -->

                </div>

            </div>
        </div>
    </div>



    <ng-template #templateTask>
        <app-taskadding [actionPlantype]="actionPlantype" [actionPlanId]="actionPlanId" [taskid]="taskid" (onClose)="closeModalf($event)" (onAdd)="changeOutput($event)"></app-taskadding>
    </ng-template>


    <ng-template #template>
        <app-create-action-plan [actionPlantype]="actionPlantype" [editActionPlanId]="editActionPlanId" (onClose)="closeModalf($event)" (onAdd)="changeImg($event)"></app-create-action-plan>
    </ng-template>