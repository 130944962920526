import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HeaderComponent } from './header/header.component';
import { HttpClientModule } from '@angular/common/http';
// import { MatCarouselModule } from '@ngmodule/material-carousel';
//import { Ng2CarouselamosModule } from 'ng2-carouselamos';


import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import { environment } from '../environments/environment';
import { CommonHeaderStripModule } from './common-header-strip/common-header-strip.module';
import { DashboardSideNavModule } from './dashboard-side-nav/dashboard-side-nav.module';
import { NplogoComponent } from './NpLogos/nplogo/nplogo.component';
import { InlineSVGModule } from 'ng-inline-svg';
//import { MessagingService } from './Providers/messaging.service';
//import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { HeaderComponentModule } from './header/header.module';


//import { CommoAlertComponent } from './actionPlans/commo-alert/commo-alert.component';
//const config: SocketIoConfig = { url: 'https://chatapi.netprophet.auto', options: {} };
// const config: SocketIoConfig = { url: 'http://10.10.10.102:3800', options: {} }



@NgModule({
  declarations: [
    AppComponent,
  //  HeaderComponent,
    NplogoComponent,
 
    //UsdOnlyDirective,

    //DashboardSideNavComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // Ng2CarouselamosModule,
    HeaderComponentModule,
    CommonHeaderStripModule,
    DashboardSideNavModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  //  SocketIoModule,
    InlineSVGModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
