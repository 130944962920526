<div
	style="width: 550px;height: auto;background-color: rgb(173, 173, 173);font-family: 'Gotham\ Book\ Regular';border-radius: 3px;">
	<div class="row ">
		<div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
			<div class="container-fluid">
				<div class="row cre_header">
					<div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
						<h6>{{type == 'A'?'Assigner':type=='F'?'Followers':'Approved By'}} <img
								src="assets/images/target_vec.png" alt=""></h6>
					</div>
					<div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;"
						data-dismiss="modal" (click)="close()">
						<img src="assets/images/cls_icon.png" alt="close">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div
	style="width: 550px;height: 500px;padding: 24px;background-color: rgb(173, 173, 173); font-family: 'Gotham\ Book\ Regular';border-radius: 3px;">

	<div class="row" style="padding-bottom: 20px;">
		<!-- <div class="col-12">
			<span>{{type == 'A'?'Add Assigner':type=='F'?'Add Followers':'Approved By'}}</span>
		</div> -->
		<div class="col-5 p-3">
			<div class="_search">
				<input autocomplete="off" placeholder="Search" type="search" (keyup)=searchFilter($event)>
				<span class="_SearchIcon"><img src="https://devpllccp2.usegomo.com/assets/images/search.png"
						style="opacity: .7;width: 16px;"></span>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12" id="{{followersArray?.length >8 ? 'content-rdEFI': 'that-one'}}">
			<div style="max-height: 311px;overflow-y: scroll;">
				<table class="table" *ngIf="followersArray?.length > 0">
					<thead>
						<tr>
							<th style="width: 50px;"></th>
							<th>User</th>
							<th>Title</th>
							<!-- <th>Location</th> -->
							<!---->
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of followersArray" (click)="fileInput.click(item)">
							<!-- <td style="width: 50px;">
								<div style="align-items: center;justify-content: center;display: flex;">
									<label class="containerC" style="margin-top: 5px;">
										<input type="checkbox" [id]="item.sc_id" [name]="item.sc_id"
											[value]="item.sc_id" [checked]="item.sc_status == true"
											(change)="checkFollower(item,$event)" (click)="fileInput.click(item)"
											#fileInput><span class="checkmark"></span>
									</label>
								</div>
							</td> -->
							<td style="width: 50px;">
                                <div style="align-items: center;justify-content: center;display: flex;"
                                    (click)="fileInput.click(item)">
                                    <label class="containerC" style="margin-top: 5px;" (click)="fileInput.click(item)">
                                        <input type="checkbox" [id]="item.sc_id" [name]="item.sc_id"
                                            [value]="item.sc_id" [checked]="item.sc_status == true"
                                            (change)="checkFollower(item,$event)" #fileInput><span class="checkmark"
                                            (click)="fileInput.click(item)"></span>
                                    </label>
                                </div>
                            </td>
							<td class="width165"><img class="contact-img"
									src="http://npapiadm.azaz.com/api/scprofileimage/{{item.sc_profile_image}}"
									onError="this.src='../../../assets/images/default-img.jpg'">
								&nbsp;{{item.sc_firstname}}
								{{item.sc_lastname}}
							</td>
							<td class="width165">{{item.sc_roleName != ''?item.sc_roleName : '---'}}</td>
							<!-- <td>{{item.sc_city != '' && item.sc_city !=null?item.sc_city : '---'}}</td> -->
						</tr>

					</tbody>
				</table>
				<table *ngIf="followersArray?.length == 0" style="width: 100%;">
					<tr>
						<td style="text-align: center;color: red;font-size: 1rem;">Sorry, No Data Found!</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="col-12 d-flex justify-content-center pt-5" *ngIf="followersArray?.length > 0">
			<!-- <div class="" style="text-align: center;">
				<span style="font-size: 14px;">Add as Followers:</span>
			</div> -->
			<div style="position: absolute;bottom: 2px;width: 30%;margin-bottom: 10px;">
				<div class="btn-sm cu-btn" (click)="saveFollowers()">
					{{type == 'A'?'Add Assigner':type=='F'?'Add Followers':'Add Approved By'}}
				</div>
			</div>
		</div>
	</div>
</div>