<div>
    <div class="modal-header" style="padding: 0 !important;">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row cre_header">
                <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                    <h6>Documents/Media</h6>
                </div>
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;cursor: pointer;">
                    <img src="assets/images/cls_icon.png" (click)="close()" alt="close">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" style="background-color: #e8ebee;">
        <app-loader-animation *ngIf="isLoading"></app-loader-animation>
        <div class="container mt-3 ">
            <div class=" col-12 col-sm-12 col-md-12 ">

                <div class="row attch_bg">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-5 col-xl-5 ">
                        <div class="input-group mb-3" *ngIf="FilesData?.length!=0">Attachments ({{FilesData?.length}})
                            <!-- <input type="text" class="form-control" placeholder="Search ..."
                                aria-label="Recipient's username">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div> -->
                        </div>
                        <!-- <div class="col-12 box">
                            <div class="row ">
                                <div class="col-2 col-lg-3 align-self-center">icon</div>
                                <div class="col-8 col-lg-6 align-self-center">
                                    <div class="title">title</div>
                                    <div class="dt">08-25-20 @8:25PM</div>
                                </div>
                                <div class="col-12 col-lg-3  d-flex align-self-center">
                                    <img src="assets/images/dwn_icon.png" style="height: 16px;">  &nbsp; <img src="assets/images/trash_icon.png" style="height: 16px;">
                                </div>
                            </div>
                        </div> -->
                        <div style="max-height: 20rem;overflow-y: auto;overflow-x: hidden;">
                            <div class="col-12 box" *ngFor="let imgdt of FilesData;let i = index">
                                <div class="row ">
                                    <div class="col-2 col-lg-3 align-self-center">
                                        <img src="assets/images/extensions/pdf.png"
                                            (click)="this.isif = true;opendocs(imgdt.apa_file)"
                                            *ngIf="imgdt.apa_file_extention  == 'pdf'" height="100" width="180"
                                            class="img1" />
                                        <img src="assets/images/extensions/csv.png"
                                            (click)="this.isif = true;opendocs(imgdt.apa_file)"
                                            *ngIf="imgdt.apa_file_extention == 'csv'" height="100" width="180"
                                            class="img1" />
                                        <img src="assets/images/extensions/txt.png"
                                            (click)="this.isif = true;opendocs(imgdt.apa_file)"
                                            *ngIf="imgdt.apa_file_extention == 'txt'" height="100" width="180"
                                            class="img1" />
                                        <img src="assets/images/extensions/doc.png"
                                            (click)="this.isif = true;opendocs(imgdt.apa_file)"
                                            *ngIf="(imgdt.apa_file_extention  == 'doc') ||(imgdt.apa_file_extention  == 'docx')"
                                            height="100" width="100" class="img" />
                                        <img src="assets/images/extensions/xls.png"
                                            (click)="this.isif = true;opendocs(imgdt.apa_file)"
                                            *ngIf="(imgdt.apa_file_extention  == 'xls')||(imgdt.apa_file_extention  == 'xlsx') ||(imgdt.apa_file_extention  == 'ods')"
                                            height="100" width="100" class="img" />

                                        <img class="userpro" (click)="this.isif = flase;opendocs(imgdt.apa_file)"
                                            *ngIf="(imgdt.apa_file_extention == 'jpg')||(imgdt.apa_file_extention == 'png')||(imgdt.apa_file_extention == 'jpeg')||(imgdt.apa_file_extention == 'gif')"
                                            height="100" width="100"
                                            src="{{apiconn1}}{{imgdt.apa_file_thumb}}"
                                            onError="this.src='../../../assets/images/default-img.jpg'" />
                                    </div>
                                    <div class="col-8 col-lg-6 align-self-center" style="cursor: default;">
                                        <div class="title">{{imgdt.apa_file_name}}</div>
                                        <!-- <div class="dt">{{imgdt.apa_updatedts | date:'short':'UTC'}}</div> -->
                                        <div class="dt">{{imgdt.apa_updatedts | date:'dd-MM-yyyy'}}</div>
                                    </div>
                                    <div class="col-12 col-lg-3  d-flex align-self-center">
                                        <img src="assets/images/dwn_icon.png" (click)="download(imgdt.apa_file)" style="height: 16px;"> &nbsp;
                                        <img src="assets/images/trash_icon.png" (click)="deleteAttachs(imgdt.apa_id)" style="height: 16px;">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-6 col-sm-6 col-lg-6 col-xl-7 text-center">
                            <div *ngIf="!ImageLoader && filename != ''">
                                <!-- <input type="text" class="form-control" > -->
                                <iframe *ngIf="isif" width="100%" height="350" [src]="sanitizer.bypassSecurityTrustResourceUrl(filename)"></iframe>
                                <img *ngIf="!isif" width="100%" height="350" src="{{this.filename}}"
                                    onError="this.src='../../../assets/images/default-img.jpg'" />
                             </div>                              
                            <div *ngIf="ImageLoader align-items-center d-flex" style="width:100%;height:350px;display:flex;align-items: center;">

                                <div class="loader"></div>
                            </div>
                        <div class="btn btn-primary" onclick="document.getElementById('fileToUpload').click()">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i>
                            <input id="fileToUpload" type="file" style="display:none;"
                                (change)="fileChangeEvent($event)"> Document / Media</div>
                    </div>
                </div>
            </div>

            <!-- <div class="modal-footer" style="background-color: #e8ebee;">
               
                        <input type="submit" (click)="saveFiles()" class="btndark" value="Save"
                            style="border:none;width: 100%;">
                  
            </div> -->
            <div class="fixed-bottom">
                <div class=" col-12 col-sm-12 col-md-12 ">
                    <div class="row pb-4 align-items-center">
                        <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11 browseimg">
                            <div *ngFor="let img of proimg;let i = index" style="float: left;">
                                <img [src]="img.img"
                                    *ngIf="showchangeimg && (img.ext == 'jpg')||(img.ext == 'png')||(img.ext == 'jpeg')||(img.ext == 'gif')" />
                                <img [src]="img.ext == 'txt'? 'assets/images/extensions/txt.png':(img.ext == 'doc' || img.ext == 'docx')?'assets/images/extensions/doc.png':(img.ext == 'pdf')?'assets/images/extensions/pdf.png':''"
                                    *ngIf="showchangeimg && (img.ext != 'jpg')&&(img.ext != 'png')&&(img.ext != 'jpeg')&&(img.ext != 'gif')" />
                            </div>
                        </div>

                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="padding-left: 0px !important;"
                            *ngIf="proimg?.length>0">
                            <img _ngcontent-yox-c1="" style="height: 2.5rem;" src="assets/images/upload_icon.png"
                                (click)="saveFiles()">
                        </div>
                        <!-- <input type="submit" (click)="saveFiles()" class="btndark" value="Save" style="border:none">   -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #imgcrop let-modal>
    <app-imagecrop [imageChangedEvent]="imageChangedEvent" (onClose)="closeModal($event)" (onAdd)="changeImg($event)">
    </app-imagecrop>
</ng-template>