<div class="popup-dialog">
	<div class="popup-content">
		<!-- popup Header -->
		<div class="popup-header">
			<div class="popup-title" *ngIf="alertType!='E'">
				<img src="assets/images/caut_del.png">

			</div>
			<!-- 
			<div class="cross_icon cur" (click)="closeModal()" *ngIf="alertType=='E'">
				<img src="assets/images/cross.png" style="width: 10px;">

			</div> -->

		</div>
		<!-- popup body -->
		<div class="popup-body">
			<div>
				<h6>{{alertContent}}</h6>
			</div>
		</div>
		<!-- popup footer -->
		<div class="popup-footer" *ngIf="alertType =='D'">
			<button type="button" style="margin-right: 1.5rem !important;background-color: #85919b !important;"
				class="btn-cancel cur" (click)="closeModal()">Cancel</button>
			<button type="button" class="btn-delete cur" (click)="deleteaction()">Delete</button>
		</div>
		<div class="popup-footer" *ngIf="alertType=='E'">
			<button type="button" class="btn-edit" (click)="editActon()">Edit</button>
			<button type="button" class="btn-cancel" (click)="closeModal()">Cancel</button>
			<!-- <div><button type="button" class="btn-achiev" (click)="achievedActon()"><img
						src='../../../assets/images/ach_btn_icon.png'>
					Achieved</button></div> -->


		</div>
		<div class="popup-footer" *ngIf="alertType=='S'">

			<button type="button" class="btn-achiev" (click)="okAction('')">
				<img src='../../../assets/images/ach_btn_icon.png'>
				OK</button>
			<button type="button" class="btn-cancel" (click)="cancelActon()">Cancel</button>

		</div>
		<div class="popup-footer" *ngIf="alertType=='T'">

			<button type="button" class="btn-edit" (click)="okAction('A')">Completed</button>
			<button type="button" class="btn-edit" (click)="okAction('C')">Cancelled</button>
			<button type="button" style="margin-right: 1.5rem !important;background-color: #85919b !important;" class="btn-cancel" (click)="okAction('E')">Cancel</button>

		</div>
		<div class="popup-footer" *ngIf="alertType =='C'">
			<button type="button" style="margin-right: 1.5rem !important;background-color: #85919b !important;"
			class="btn-cancel" (click)="closeModal()">Cancel</button>
			<button type="button" class="btn-delete" (click)="complete()">Complete</button>
			</div>

			<div class="popup-footer" *ngIf="alertType =='A'">
					<button type="button" style="margin-right: 1.5rem !important;background-color: #85919b !important;"
					class="btn-cancel" (click)="closeModal()">Cancel</button>
					<button type="button" class="btn-delete" (click)="Archive()">Archive</button>
			</div>

			<div class="popup-footer" *ngIf="alertType =='o'">

                <button type="button" style="margin-right: 1.5rem !important;background-color: #85919b !important;"

                class="btn-cancel" (click)="closeModal()">Cancel</button>

                <button type="button" class="btn-delete" (click)="ok()">ok</button>

        </div>

		<div class="popup-footer" *ngIf="alertType =='H'">

			<button type="button" style="margin-right: 1.5rem !important;background-color: #85919b !important;"

			class="btn-cancel" (click)="confirm('N')">Cancel</button>

			<button type="button" class="btn-delete" (click)="confirm('S')">ok</button>

	</div>
	</div>
</div>