import { AfterContentChecked, AfterViewChecked, Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { NPApi } from '../Providers/NP-Api';
import { Common } from '../common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LinechatComponent } from '../linechat/linechat.component';
const userprofileimageurl = environment.ImageUrl1;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userInfo: any;
  isLoading:any;false;
  modules:any;
  // logo:any='../../assets/SvgLogo/no_logo.svg';
  @Output() binddetailsdata = new EventEmitter();
  @Output() bindSummeryPerformance = new EventEmitter();
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.repdownarrow(2);   
    event.stopPropagation();    
    }
  constructor(private api: NPApi, public comm: Common, private router: Router,private ngbmodal: NgbModal,) {
    if(this.comm.pageid != 21)
    {
      this.msgNtfCount();
    }
    else{
      this.msgcnt=0;
    }
  }
  bindlogo(){
    return '../../assets/SvgLogo/no_logo.svg';
  }
  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    console.log(JSON.stringify(this.userInfo));
    this.comm.proimg = userprofileimageurl+this.userInfo.profilepic;

    this.modules = localStorage.getItem("npModuleIds");
    this.comm.moduleids = this.modules;
    this.api.getStoreValue().subscribe(res =>{
      console.log(res,'response');
      if(this.comm.pageid != 21)
      {
        this.msgNtfCount();
      }
      else{
        this.msgcnt=0;
      }
      this.getnotificationsList();
      
    })
   // this.api.socketopen();
    // this.api.getmessages().subscribe((message: any) => {
    //   if(message.dealerid==localStorage.getItem('store_id') && message.fromid!=this.userInfo.id){
    //     if(this.comm.pageid != 21)
    //     {
    //       this.msgNtfCount();
    //     }
    //     else{
    //       this.msgcnt=0;
    //     }
    //     this.getnotificationsList();
    //   }
    // });

  }

  msgcnt:any;
  msgNtfCount(){
    if(this.userInfo!=undefined){
    const obj={      
    "CU_USER_ID": this.userInfo.id,
    "CONV_Dealer_ID":localStorage.getItem('store_id')
    }
    console.log(obj)
    this.api.MessangerUnreadStatusCount(obj).subscribe((data:any)=>{
     // console.log(data,'countdata');
      this.msgcnt = data.response.unreadmsgount;
      // this.msgcnt = 30;
      console.log(this.msgcnt,'count')
    })
  }
    }

  bind_default(){
    let userInfo = JSON.parse(localStorage.getItem("npUserInfo"));
    if(this.userInfo.sc_defaultscreen != null && this.userInfo.sc_defaultscreen!=""
     && this.userInfo.sc_defaultscreen!=undefined){
      if(this.userInfo.sc_defaultscreen == '9')
      {
        this.comm.selectedlink = 31;
      // this.router.navigate(['Dash']);
      this.router.navigate(['/4']);
      }
      else if(this.userInfo.sc_defaultscreen == '6')
      {
        this.comm.selectedlink = 3;
        this.router.navigate([this.userInfo.sc_defaultscreen]);
      }
      else{
        this.comm.selectedlink = 10;
        // this.router.navigate(['1'])
        this.router.navigate(['/4'])
      }
    }
    else{
      this.comm.selectedlink = 10;
      this.router.navigate(['1'])
    }
  }

  logOut() {
    this.api.logout();
  }

  APclick() {
    this.comm.selectedlink = 2;
    this.router.navigate(['/2'])
    .then(() => {
      // window.location.reload();
    });
  //   setTimeout(() => {
  //     //this.comm.actionPlanActiveFlag = true;
  //     this.comm.selectedlink = 2;
  //  //   this.router.navigate(['/2']);
  //     window.location.href.includes('/2')
  //   }, 1000);
    //console.log('header', this.comm.fromHEAD)
  }
//   ngDoCheck() {
// 
//     if (window.location.href.includes('/2')) {
//     
//       this.comm.selectedlink = 2;
//     } else if (window.location.href.includes('/4')) {
//      
//       this.comm.selectedlink = 1;
//     }
// 
// else if (window.location.href.includes('/8')) {
//  
//   this.comm.selectedlink = 8;
//   
//  } else if (window.location.href.includes('/7')) {
//   
//   this.comm.selectedlink = 7;
//  }
//  
//     
//  
//   }
usersettings(){
  this.router.navigate(['/3'])
}
  Groupclick(){
    if(this.comm.pageid!=4){
    setTimeout(() => {
      this.comm.selectedlink = 1;
      this.comm.selectedStore = [];
      this.comm.grididividual=0;
   // this.comm.storeinfo.push({Store_Id: "1",Store_Name: "Automotive Group"})
       this.router.navigate(['/4'])
  }, 1000);
}
  else if(this.comm.pageid==4){
    this.router.navigate(['/4'])
    .then(() => {
      window.location.reload();
    });
  }
  }
  bindPerformance() {
    this.comm.dbflag = 1;
    this.comm.selectedlink = 3;
   // this.bindPerformancedata.emit(
      this.router.navigate(['6']);
     // )
  }

  
  gridclick() {
    
    this.comm.selectedlink = 1;
    this.comm.grididividual = 1;
    if(this.comm.pageid!=4){
      this.router.navigate(['4'])
    }
      else if(this.comm.pageid==4){
        this.router.navigate(['/4'])
        .then(() => {
          window.location.reload();
        });
      }
    
  }

  task(){

    this.comm.selectedlink = 8;
    // routerLink="/1"
  this.router.navigate(['/8'])
  }

  DBclick(){
    //setTimeout(() => {
      this.comm.selectedlink = 31;
     // routerLink="/1"
   this.router.navigate(['/Dash'])
//  }, 1000);
  }
  Adminclick(){
    //setTimeout(() => {
      this.comm.selectedlink = 7;
     // routerLink="/1"
   this.router.navigate(['/7'])
//  }, 1000);
  }

  openMessenger(){
    let id = 0;
    this.comm.title="";
    this.isLoading = true;
    this.router.navigate(['messenger', id]);
    }

    // bindSummery_Performance() {
    //   localStorage.setItem('dbflag', '1');
    //   if(this.comm.pageid!=1){
    //   setTimeout(() => {
    //     // this.comm.dbflag = 2;
    //     this.comm.selectedlink = 10;
    //     this.bindSummeryPerformance.emit(this.router.navigate(['1']))
    //   }, 1000);
    // }
    // else if(this.comm.pageid==1){
    //     setTimeout(() => {
    //        // this.comm.dbflag = 2;
    //         this.comm.selectedlink = 10;
    //         this.router.navigate(['1']).then(() => {
    //          window.location.reload();
    //        });
    //        }, 1000);
    //  }
    
    // }

    bindSummery_Performance() {
      this.comm.selectedlink = 10;      
      this.router.navigate(['/10']);
    }
      
      
    binddetails() {
      this.comm.selectedlink = 4;      
      this.router.navigate(['/1']);
    }
  

    getnotificationsList(){
      let body = {
        userid: this.userInfo.id,
        type: '',
        Storeid: localStorage.getItem('store_id')
      };
      // this.isLoading = true;
      this.api.Getnotifications(body).subscribe((res: any) => {
       // this.notifyslist=[];
        // this.isLoading = false;
        if (res.status == 200) {
          this.comm.notifyslist = res.response;
        }
      }, err => {
        // this.isLoading = false;
      });
    }

    selectedLinkPreviousValue: number;
    Trendsclick() {
      this.api.getLocation('Trends');
      this.selectedLinkPreviousValue = this.comm.selectedlink;
      //console.log(this.selectedLinkPreviousValue, 'kk');
   
      this.comm.selectedlink = 5;
      // this.trendsdata.emit();
      //this.ngbmodalActive = this.ngbmodal.open(LinechatComponent, { size: 'lg', backdrop: 'static' });
      const modalRef = this.ngbmodal.open(LinechatComponent, { size: 'lg', backdrop: 'static' });
      modalRef.componentInstance.previousValue = this.selectedLinkPreviousValue;
      modalRef.componentInstance.seclabel = "";
    }

    hotnotlink(){
      //setTimeout(() => {
        this.comm.selectedlink = 51;
       // routerLink="/1"
     this.router.navigate(['/hotnot']).then(() => {
      // window.location.reload();
    });
  //  }, 1000);
    }

    openReports(){
      this.comm.selectedlink = 61;
      this.router.navigate(['#aireport']);
      }

    downarrow:boolean = false;
    repdownarrow(t){
      if(t == 1){   
      this.downarrow = !this.downarrow;
      event.stopPropagation();     
      }else{
      this.downarrow = false;    
      }    
      }

    openUserstats(){
      this.comm.selectedlink = 61;
      this.router.navigate(['9']);
    }
    insite_click(){
    //routerLink="/5";
    this.router.navigate(['5']);
  }
}
