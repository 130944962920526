<app-loader-animation *ngIf="isLoading"></app-loader-animation>
<div class="">
    <div class="modal-header" style="padding: 0 !important;">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="row cre_header">
                <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                    <h6>Tasks <img style="margin-left: 10px;" src="assets/images/task_icon.png" alt=""></h6>
                </div>
                <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;">
                    <img src="assets/images/cls_icon.png" (click)="closeTask()" alt="close">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-body" style="padding: 0rem 0rem 1.5rem;background-color: #e8ebee;">
        <div class="container">
            <div class="row expense justify-content-center">
                <span>Target Area:</span> <label>{{actionPlanTitle}}</label>
                <span class="pl-lg-5">Target Date:</span> <label>{{actionPlanTargetDate | date: 'MM/dd/yy'}}</label>
            </div>

            <div class="row justify-content-center" *ngIf="tasksList?.length !=0" style="background-color: #e8ebee;padding: 0rem 2rem;" [ngClass]="tasksList?.length > 4 ? 'task_height' : ''">

                <div class="col-md-12 px-4 py-3">
                    <div class="row assigner" style="padding: 10px 0;align-items: center;" *ngFor="let item of tasksList">
                        <div class="col-md-2 task px-lg-0" style=" white-space: nowrap; width: 50px; overflow: hidden;text-overflow: ellipsis; ">
                            <!-- <select>
													<option>Set up sales meeting</option>
												</select> -->
                            <div maxlength="50" class="task_input" title={{item.ta_TaskName}}>
                                <div class="ecli_text">{{item.ta_TaskName}}</div>
                            </div>
                            <!-- <label>{{item.ta_TaskName}}</label> -->
                        </div>
                        <div class="col-md-1 ">
                            <span>Assigned:</span>
                        </div>
                        <div class="col-md-3" *ngIf="item.Assignedby !=''">
                            <div class="c_dropdown cur" *ngFor="let itemAssigned of convertAssignerXml(item.Assignedby)" (click)="openActionPlanFollowers(template,'A',item)">
                                <ul>
                                    <li>
                                        <div class="">
                                            <img class="pic" src="{{userprofileimageurl}}/{{itemAssigned?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                        </div>
                                        <div class="des">
                                            <div class="name">
                                                {{itemAssigned?.sc_firstname != 'null'?itemAssigned?.sc_firstname:''}} {{itemAssigned?.sc_lastname != 'null' ?itemAssigned?.sc_lastname:''}}
                                            </div>
                                            <div class="role">{{itemAssigned?.sc_roleName}}</div>
                                        </div>
                                    </li>
                                    <li>Europe</li>
                                    <li>America</li>

                                    <span></span>
                                </ul>



                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="item.Assignedby ==''">

                            <div class="col-md-12 pl-lg-0" style="padding: 0 5rem;" (click)="openActionPlanFollowers(template,'A',item)"><img src="assets/images/cir_sml.png">
                            </div>
                        </div>

                        <!-- <div class="col-md-4">

							<div class="col-md-1 pl-lg-0">{{item.ta_Assignby_U_UId}}
							</div>
						</div> -->
                        <div class="col-md-3 pl-lg-0" style="max-width: 10.3rem;height: 40px;">
                            <!-- <input type="date" class="form-control date_icon" [ngModel]="item.ta_DueDate"
								[value]="item.ta_DueDate" (change)='onOptionsDateUpdate($event,item)' style="height: 100%;"> -->
                            <dp-date-picker theme="dp-material" class="form-control date_icon" [(ngModel)]="item.ta_DueDate" value="{{item.ta_DueDate|date:'MM/dd/yy'}}" [config]="datePickerConfig" required="true" style="font-family:'Gotham\ Black';font-size:0.9rem;height: calc(2.25rem + 4px);"
                                (change)='onOptionsDateUpdate($event,item)'>
                            </dp-date-picker>
                        </div>
                        <!-- <div class="col-md-1" style="padding:0 3rem">
							<span>Priority:</span>
						</div> -->
                        <!-- <div class="col-md-1 priority px-lg-0"
							[ngClass]="item.ta_Priority ==1?'red':item.ta_Priority==2?'yellow':'green'">
							<label
								style="margin-bottom: 0rem;color: white;">{{item.ta_Priority ==1?'HIGH':item.ta_Priority==2?'MEDIUM':'LOW'}}</label>

						</div> -->
                        <div style="padding: 0 .3rem;"><span>Priority:</span></div>
                        <div class="col-md-2 priority px-lg-0" style="max-width:80px;" [ngClass]="item.ta_Priority =='1'?'red':item.ta_Priority=='2'?'yellow':'green'">
                            <label style="margin-bottom: 0rem;color: white;">{{item.ta_Priority ==1?'HIGH':item.ta_Priority==2?'MEDIUM':'LOW'}}</label>
                            <!-- <select (change)='onOptionsSelectedUpdate($event,item)' [(ngModel)]="item.ta_Priority"
								style="font-family: 'Gotham\ Book\ Regular'">
								<option value='1' class="red">High</option>
								<option value='2' class="yellow">Medium</option>
								<option value='3' class="green">Low</option>
							</select> -->
                        </div>
                        <div class="col-1" *ngIf="actionPlanId !=0">
                            <img src="assets/images/edit_np.png" style="cursor: pointer;" (click)="edittask(item.ta_Id,templateTask,pop,'E','Select an Action for this Task')">
                        </div>

                    </div>


                    <!-- <div class="row mt-3">
						<div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
							<a routerLink="#"><img src="assets/images/Add-Task.png" alt="Addtask"></a>
						</div>
					</div> -->
                    <!-- <div class="row justify-content-center">
						<div class="col-12 col-lg-3 pt-3">
							<div class="btndark" (click)="taskAssign()">save</div>
						</div>
					</div> -->
                </div>

            </div>
            <div class="row justify-content-center" style="background-color: #e8ebee;padding: 0 .2rem; margin-top:1rem;">
                <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 task_icon cur" (click)="openActionPlanTask(templateTask)">
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #template let-modal>
    <app-acton-plan-followers [followersassigned]="followers" [taskAssignee]="taskAssignee" [assignee]="assignee" [type]="type" (onClose)="closeModalf($event)" (onAdd)="changeImg($event)"></app-acton-plan-followers>
</ng-template>


<ng-template #templateTask>
    <app-taskadding [actionPlantype]="actionPlantype" [actionPlanId]="actionPlanId" [taskid]="taskid" (onClose)="closeModalf($event)" (onAdd)="changeOutput($event)"></app-taskadding>
</ng-template>