import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommoAlertComponent } from './commo-alert.component';



@NgModule({
  declarations: [CommoAlertComponent],
  imports: [
    CommonModule
  ],
  exports: [CommoAlertComponent],
  entryComponents: [CommoAlertComponent],
})
export class CommoAlertModule { }
