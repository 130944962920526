import { Directive, OnInit, forwardRef, ElementRef, HostListener, Renderer2, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUsdOnly]'
})
export class UsdOnlyDirective {
  @Input() actionPlanTitle: any;
  flag = '';
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(public ngControl: NgControl, private el: ElementRef) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
    //this.onKeyDown(event.target.value);
    ////console.log(this.actionPlanTitle, 'b');
    if (this.actionPlanTitle.includes("%")) {
      this.flag = '%';
    } else if (this.actionPlanTitle.includes("$")) {
      this.flag = "$";
    } else {
      this.flag = '';
    }

  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    //this.onKeyDown(event.target.value);
    this.onInputChange(event.target.value, true);
  }


  onInputChange(event, backspace) {
    let newVal = event.replace(/[^0-9.-]*/g, '');   
    if (backspace && newVal.length <= 6) {
      //newVal = newVal.substring(0, newVal.length - 1);
    }
    // if (newVal.length === 0) {
    //   newVal = '';
    // } else if (newVal.length <= 3) {
    //   newVal = newVal.replace(/^(\[^0-9.]{0,3})/, '$1');
    // } else if (newVal.length <= 4) {
    //   newVal = newVal.replace(/^(\[^0-9.]{0,2})(\d{0,3})/, '$1,$2');
    // } else if (newVal.length <= 5) {
    //   newVal = newVal.replace(/^(\d{0,2})(\d{0,3})/, '$1,$2');
    // } else if (newVal.length <= 6) {
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1,$2');
    // } else if (newVal.length <= 7) {
    //   newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
    // } else if (newVal.length <= 8) {
    //   newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
    // } else if (newVal.length <= 9) {
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1,$2,$3');
    // } else {
    //   ///newVal = newVal.substring(0, 10);
    //   newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/, '$1,$2,$3,$4');
    // }
    newVal = this.toFixedSpecial(newVal);

    if (this.actionPlanTitle.includes("%")) {
      this.flag = '%';
    } else if (this.actionPlanTitle.includes("$")) {
      this.flag = "$";
    } else {
      this.flag = '';
    }
    if (this.flag == "%") {
      this.ngControl.valueAccessor.writeValue(newVal + this.flag);
    } else if (this.flag == "$") {
      this.ngControl.valueAccessor.writeValue(this.flag + newVal);
    } else {
      this.ngControl.valueAccessor.writeValue(newVal);
    }
    console.log(this.actionPlanTitle)
  }

  toNumber(val) {
    let valArr = val.split('');
    let valFiltered = valArr.filter(x => !isNaN(x))
    let valProcessed = valFiltered.join('')
    return valProcessed;
  }

  toFixedSpecial(newVal) {
    var n = newVal
    if (n != null) {
      if (n == ".") {
        return "0."
      }
      else if (n.length == 2 || n.length == 3) {
        return n;
      } else {
        var parts = n.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); if (parts[1] > 0)
          return parts.join('.');
        else
          return parts[0];
      }
    }
  }
}
