<!-- Generated template for the DropdownlistComponent component -->
<div class="modal-header">
  <div class="row  att_header">
    <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11 heading">
      Image Crop
    </div>
    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 text-right" style="cursor: pointer;">
      <img _ngcontent-yox-c1="" alt="close" src="assets/images/cls_icon.png" (click)="close()">
    </div>
  </div>
</div>
<div class="modal-body" style="padding: 0rem 0rem 1.5rem;background-color: #e8ebee;">
  <div class="container mt-3 ">
    <div class=" col-12 col-sm-12 col-md-12 ">
      <image-cropper style="height: 280px;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
        [aspectRatio]="3 / 3" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div>
      <div class="row" style="padding-top: 1em;padding-bottom: 1em;justify-content: center;">
        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="text-align: center;">
          <button (click)="Save()" class="btndark" style="border:none" type="submit">Save</button>
        </div>
      </div>
      <!-- <div style="text-align: center;">
        <div class="row" style="padding-top: 1em;">
            <div class="col-md-3">
                <button (click)="Save()" class="save_btn" type="submit">Save</button>
            </div>
            <div class="col-md-3">
                <button (click)="closeModal()" class="cancel_btn" type="button">Cancel</button>
            </div>
        </div>    </div> -->
    </div>
  </div>
</div>