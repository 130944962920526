import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateActionPlanComponent } from './create-action-plan.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActonPlanFollowersModule } from '../acton-plan-followers/acton-plan-followers.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbActiveModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ActionPlanAttachModule } from '../action-plan-attach/action-plan-attach.module'
import { ActionPlanTakModule } from '../action-plan-tak/action-plan-tak.module';


import { CommoAlertModule } from '../commo-alert/commo-alert.module';
import { UsdOnlyModule } from 'src/app/directive/usd-only/usd-only.module';
import { NgSelect2Module } from 'ng-select2';
import { CurrencyModule } from 'src/app/pipes/currency/currency.module';
import { LimitCharsPipeModule } from 'src/app/pipes/limit-chars/LimitCharsPipeModule';
import { TaskaddingModule } from 'src/app/Tasks/taskadding/taskadding.module';

import { DpDatePickerModule } from 'ng2-date-picker';

@NgModule({
  declarations: [CreateActionPlanComponent],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ActonPlanFollowersModule,
    NgbModalModule,
    ActionPlanAttachModule,
    ActionPlanTakModule,
    UsdOnlyModule,
    CommoAlertModule,
    NgSelect2Module,
    CurrencyModule,
    LimitCharsPipeModule,
    TaskaddingModule,
	DpDatePickerModule
  ],
  exports: [CreateActionPlanComponent],
  entryComponents: [CreateActionPlanComponent],
  providers: [NgbActiveModal]
})
export class CreateActionPlanModule { }
