import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertifyService } from './alertify/alertify.service';
import { NPApi } from './NP-Api';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private npSrv: NPApi, private alertify: AlertifyService, private router: Router) { }
  canActivate(): boolean {
    if (this.npSrv.loggedIn()) {
      return true;
    }

  //  this.alertify.error('You are not login to access this page');
    this.router.navigateByUrl("/");
    return false;


  }

}
